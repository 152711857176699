<template>
  <div class="hooks-wrapper">
    <div class="hooks-header">
      <span class="label">Set Hooks for Your Avatar</span>
      <!-- <bv-tip
        raw-content
        placement="bottom"
        :content="tip"
        :showAfter="0"
      >
        <svg-icon 
          name="script_help"
          :size="18"
        />
      </bv-tip> -->
    </div>
    <div class="hooks-desc">
      Upload videos as hook collections for your Avatar
    </div>
    <div class="hooks-list">
      <div class="new-hook" @click="uploader?.handleClickUpload">
        <bv-uploader
          v-model="files"
          ref="uploader"
          :accept="accept"
          :preUploadOptions="{ toolName: 'similarCharacterHook' }"
          :beforePreProcess="beforePreProcess"
        />
        <svg-icon
          name="editor_plus"
          :size="36"
          color="#646A73"
        />
      </div>
      <div 
        v-for="file in files"
        class="hook-container"
      >
        <div class="processing-mask" v-if="file.state === 2">
          <div class="progress-box">
            <span class="progress-tip">{{`Uploading ${file.progress.toFixed(2)}%`}}</span>
            <div class="progress-bar">
              <div class="progress" :style="{ width: `${file.progress}%` }"></div>
            </div>
          </div>
        </div>
        <div class="error-mask" v-else-if="file.state === 1">
          <div class="error-tip-container">
            <svg-icon 
              name="icon_warn" 
              :size="24" 
              color="#FFFFFF" 
              alt="warn icon" 
            />
            <span class="error-tip">The process failed</span>
          </div>
        </div>
        <svg-icon 
          v-if="file.state === 0"
          color="#FFFFFF"
          name="icon_delete" 
          class="delete-button"
          :size="24"
          @click="deleteHook(file)"
        />
        <div 
          v-if="file.state === 0"
          class="duration"
        >
          {{ secondsToMs(file.duration) }}
        </div>
        <svg-icon 
          v-if="file.state === 0"
          :size="24" 
          class="preview"
          name="editor_library_preview" 
          @mousedown.stop="preview(file)"
        />
        <img v-if="file.coverPic" :src="file.coverPic" alt="">
      </div>
    </div>
    <Previewer ref="previewer" :showArrow="false" :record="previewFile" />
  </div>
</template>

<script setup>
import { DataUnit } from "@/utils";
import { secondsToMs } from "@/utils/util";
import { VIDEOTYPE } from "@/utils/type.ts";
import { deleteCharacterMaterial, startAiProcess } from "@/api/character";
import { useModalManager } from "@/components/common/custom-modal/instance";
import Previewer from "@/pages/space/components/material-previewer/index.vue";

const modalManager = useModalManager();

const tip = `
  <ul style='list-style: disc; padding: 7px 13px 7px 26px; width: 298px; font-size: 14px; font-weight: 400; lint-height: 22px;'>
    <li>Upload your own media, or skip this option let AI ​​match automatically.</li>
    <li>Up to 15 files, including max 5 videos ( each < 30s ), visuals over 1080P will be compressed and take more time.</li>
  </ul>
`;
const accept = [
  {
    types: VIDEOTYPE.split(","),
    maxSize: 200 * DataUnit.MB,
  },
];

const props = defineProps({
  info: {
    type: Object,
    required: true,
  }
});

const files = ref([]);
const uploader = ref(null);
const previewer = ref(null);
const previewFile = ref(null);

const preview = (value) => {
  previewFile.value = { ...value, preview1080Url: value.preview1080Url || value.url, type: "video" };
  previewer.value.open = true;
};

const deleteHook = (file) => {
  let deleteLoading = false;
  const confirm = async () => {
    if(deleteLoading) return;
    deleteLoading = true;
    const params = {
      mid: file.mid,
      type: 4,
    };
    const { success } = await deleteCharacterMaterial(params);
    if (success) {
      const index = files.value.findIndex((i) => i.mid === file.mid);
      files.value.splice(index, 1);
    }
    modalManager.modal.open = false;
    deleteLoading = null;
  };
  modalManager.applyTemplate("warning", {
    icon: "orangeWarn",
    key: "delete-character-hook-modal",
    title: "Upload disabled after deletion",
    content: "Stickers cannot be restored after being deleted. Continue deleting?",
    confirmText: "Delete",
    closeAfterConfirm: false,
    onConfirm: confirm,
  });
};

const beforePreProcess = async (file) => {
  return new Promise((resolve, reject) => {
    const params = {
      characterId: props.info.characterId,
      mid: file.mid,
      type: 4,
    };
    startAiProcess(params)
      .then(({ success }) => {
        if (success) {
          resolve();
        }
        else {
          reject();
        }
      });
  })
};

const setup = () => {
  const { info } = props;
  if (info.hooks.length > 0) {
    files.value = files.value.concat(info.hooks);
  }
  for (const file of files.value) {
    if (file.state > 1) {
      uploader.value
        ?.getUploadState(file)
        .then((info) => Object.assign(file, info))
        .catch((e) => Object.assign(file, e));
    }
  }
};

onMounted(setup);
</script>

<style lang="scss" scoped>
.hooks-wrapper {
  width: 100%;
  
  & :deep(.bv-tooltip-content-container) {
    width: fit-content; 
    height: fit-content;
  }
}

.hooks-header {
  width: 60%;
  min-width: 430px;
  display: flex;
  align-items: center;

  & svg {
    cursor: pointer;
  }
}

.label { 
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-right: 6px;
}

.hooks-desc {
  width: 60%;
  min-width: 430px;
  color: #8F959E;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 6px;
}

.hooks-list {
  width: 100%;
  min-height: 224px;
  display: grid;
  gap: 18px;
  margin-top: 18px;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
}

.new-hook {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
  border: 1px solid rgba(31, 35, 41, 0.08);
  background: #FFF;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #F4F5F7;
  }
}

.hook-container {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
    background: #F7F7F8;
    -webkit-user-drag: none;
  }
}

.processing-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.54);  
  z-index: 1;
}

.progress-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  inset: 0;
}

.progress-bar {
  position: relative;
  width: 70%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow-x: hidden;
}

.progress {
  height: 100%;
  background-color: #ffffff;
  transition: width 0.2s;
}

.progress-tip {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  margin-bottom: 16px;
}

.error-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.54);  
  z-index: 1;
}

.error-tip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.error-tip {
  color: #ffffff;
  font-size: 14px;
  margin-top: 4px;
}

.delete-button {
  position: absolute;
  top: 12px;
  right: 14px;
  padding: 3px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.45);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s;
}

.hook-container:hover .delete-button,
.hook-container:hover .preview {
  opacity: 1;
}

.duration {
  position: absolute;
  bottom: 14px;
  right: 14px;
  padding: 0 8px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
}

.preview {
  position: absolute;
  top: 40px;
  right: 14px;
  padding: 3px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.45);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s;
}
</style>