<template>
  <div
    class="h-full w-full flex justify-center items-center relative"
    :class="{ 'product-to-Video-container': step == 1 }"
  >
    <Upload
      @nextStep="handleNextStep"
      v-if="step == 1"
      @onLoading="handleLoading"
    />
    <FeatureEdit
      v-if="step == 2"
      :imageList="imageList"
      :productInfo="productInfo"
      @onLoading="handleLoading"
      @onGenerated="handleGenerated"
    />
    <div
      class="w-full h-full absolute top-0 left-0 loading-mask"
      v-if="loading"
    ></div>

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onBeforeMount, watch } from 'vue';

import Upload from './ImportFile.vue';
import FeatureEdit from './components/featureEdit/index.vue';
import { useRouter, useRoute } from 'vue-router';
import { sessionGetItem, sessionSetItem } from '@/utils/storage';
import { useTrackStore } from '@/store/modules/track';
import { useFeatureEdit } from './components/featureEdit/store/index.js';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { checkResource } from '@/api/script';

const { clearEventData, collectData, track } = useTrackStore();
const featureEditStore = useFeatureEdit();
const router = useRouter();
const route = useRoute();
const step = ref(1);
const imageList = ref([]);
const productInfo = ref({});
const loading = ref(false);
const subscribeModalVisible = ref(false);
const modalManager = useModalManager();

clearEventData('boolvideo_create_input');

onMounted(() => {
  collectData('boolvideo_page_click_result', {
    video_type: 'product_to_video',
    click_type: sessionGetItem('create_from'),
    result: 'success',
  });
  track('boolvideo_page_click_result');
});

const handleNextStep = ({ _imageList = [], _productInfo = {} }) => {
  step.value = 2;

  router.replace({
    path: route.path,
    query: {
      ...route.query,
      type: 'feature',
    },
  });

  imageList.value = _imageList;
  productInfo.value = _productInfo;
  collectData('boolvideo_create_upload', {
    video_type: 'product_to_video',
  });
  track('boolvideo_create_upload');
};

const handleLoading = (value) => {
  loading.value = value;
};

const handleGenerated = async (formState, cacheForm) => {
  sessionSetItem('generateParams', formState);
  sessionSetItem('cacheForm', cacheForm);

  if (formState.type == 0) {
    router.push({
      path: '/product-to-video/generating',
    });
    return;
  }

  const { noCredit, msg, code } = await checkResource({
    resource: [90001, 70001],
  });

  if (code == 101011) {
    modalManager.applyTemplate('paymentFail', {});
    return;
  }

  if (noCredit) {
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  router.push({
    path: '/product-to-video/voice/generating',
  });
};

watch(
  () => route.query.type,
  (type) => {
    if (type == 'feature') {
      step.value = 2;
      const cacheForm = sessionGetItem('cacheForm');
      if (!cacheForm) {
        return;
      }

      imageList.value = cacheForm.cacheImageList;
      productInfo.value = {
        productDesc: cacheForm.productDesc,
        bkId: cacheForm.bkId,
        duration: cacheForm.duration,
        effectCustomization: cacheForm.effectCustomization,
        language: cacheForm.language,
        purposeIds: cacheForm.purposeIds,
        size: cacheForm.size,
        type: cacheForm.type,
        url: cacheForm.url,
      };

      return;
    }
    step.value = 1;
  },
  {
    immediate: true,
  }
);


watch(
  () => step.value,
  (step) => {
    if (step == 2) {
      collectData('boolvideo_create_upload_result', {
        video_type: 'product_to_video',
        result: 'success',
      });
      track('boolvideo_create_upload_result');
    }
  }
);

onBeforeMount(async () => {
  await featureEditStore.getVideoPurpose();
});
</script>

<style lang="scss" scoped>
.product-to-Video-container {
  min-height: 600px;
}

.loading-mask {
  background: rgba(255, 255, 255, 0.35);
}

.loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>
