<script setup>
import { useCreatorStore, useHistoryStore, useDraftStore } from "../../stores";

const { creator, tracks } = useCreatorStore();
const { commit } = useHistoryStore();
const { updateDraft } = useDraftStore();
const operation = ref(null);
const reversedTracks = computed(() => {
  const reversed = [];
  for (let i = tracks.value.length; i--; i >= 0) {
    const track = tracks.value[i];
    reversed.push(track);
  }
  return reversed;
});

onMounted(() => {
  const scrollElement = document.querySelector(".timeline-scrollbar");
  scrollElement.addEventListener("scroll", scroll);
});
onBeforeUnmount(() => {
  const scrollElement = document.querySelector(".timeline-scrollbar");
  scrollElement.removeEventListener("scroll", scroll);
});

function scroll() {
  const scrollElement = document.querySelector(".timeline-scrollbar");
  operation.value.style.top = `${-scrollElement.scrollTop}px`;
}

function submit() {
  commit();
  updateDraft();
}

function changeVisible(track) {
  const visible = !track.visible;
  track.conf.visible = visible;
  track.setVisible(visible);
  creator.value.refresh();
  submit();
}

function changeMuted(track) {
  const muted = !track.muted;
  track.conf.muted = muted;
  track.setMuted(muted);
  creator.value.refresh();
  submit();
}
</script>
<template>
  <div class="timeline-operation-wrapper">
    <div ref="operation" class="timeline-operation">
      <div
        v-for="track in reversedTracks"
        class="operation"
        :key="track.id"
        :class="track.kind"
      >
        <div class="left">
          <icon-button
            v-if="!['audio', 'speech'].includes(track.kind)"
            :name="track.conf.visible ? 'editor_timeline_visible' : 'editor_timeline_invisible'"
            :size="18"
            @click="changeVisible(track)"
          />
        </div>
        <div class="right">
          <icon-button
            v-if="['primary', 'image', 'audio'].includes(track.kind)"
            color="#646A73"
            :name="track.conf.muted ? 'editor_timeline_muted' : 'editor_timeline_unmuted'"
            :size="18"
            @click="changeMuted(track)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.timeline-operation-wrapper {
  width: 82px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 6;
}
.timeline-operation {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  padding: 54px 4px 26px 4px;
  display: flex;
  flex-direction: column;
}
.operation {
  position: relative;
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  background-color: #f3f5f7;
  padding: 0 8px;
}
.operation + .operation {
  margin-top: 10px;
}
.operation.primary,
.operation.image {
  height: 56px;
}
.left {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
}
.right {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
</style>
