import { getEffectList } from "@/api/resources";

const effects = ref(null);
const effectsLoading = ref(true);

export const useResourceStore = () => {
  const getEffects = async () => {
    const helper = async () => {
      effectsLoading.value = true;
      const { success, data } = await getEffectList();
    
      if (success) {
        effects.value = data;
      }
      effectsLoading.value = false;
    };
    if (!effects.value) {
      await helper();
    }
    return effects.value;
  }

  return {
    getEffects,
    effectsLoading,
  }
};