<template>
  <div class="info-wrapper">
    <el-input 
      class="character-edit-name"
      ref="inputer"
      v-model="name"
      placeholder="What's your avatar's name?"
      @change="handleNameChange"
    />

    <div class="avatar-container" @click="uploader?.handleClickUpload">
      <bv-uploader
        v-model="files"
        ref="uploader"
        :accept="accept"
        :preUploadOptions="{ toolName: 'similarCharacterCover' }"
        @success="handleUploadSuccess"
        @progress="handleUploadProgress"
        @beforeAddFiles="handleUploadStart"
        :beforePreProcess="beforePreProcess"
      />
      <div class="uploaded-avatar-mask" v-if="cover && state === 0">
        <svg-icon name="editor_plus" color="#FFFFFF" :size="24" />
        <span>Replace</span>
      </div>
      <div class="uploading-avatar-mask" v-else-if="state === 2">
        <div class="progress-box">
          <span class="progress-tip">{{`${progress.toFixed(2)}%`}}</span>
          <div class="progress-bar">
            <div class="progress" :style="{ width: `${progress}%` }"></div>
          </div>
        </div>
      </div>
      <div class="uploaded-avatar" v-if="cover"> 
        <img :src="cover" alt="">
      </div>
      <div class="upload-avatar" v-else>
        <svg-icon name="editor_plus" color="#646A73" :size="24" />
        <span>Avatar</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DataUnit } from "@/utils";
import { IMAGETYPE } from "@/utils/type.ts";
import { startAiProcess, putCharacterName } from "@/api/character";

const props = defineProps({
  info: {
    type: Object,
    required: true,
  }
});
const name = ref("");
const state = ref(0); //0: success 1: fail 2: uploading
const cover = ref("");
const files = ref([]);
const progress = ref(100);
const inputer = ref(null);
const uploader = ref(null);

const accept = [
  {
    types: IMAGETYPE.split(","),
    maxSize: 10 * DataUnit.MB,
  },
];

const handleUploadSuccess = (data) => {
  progress.value = 100;
  state.value = 0;
  cover.value = data.coverPic;
  const newCover = {
    mid: data.mid,
    coverPic: data.coverPic,
    state: 0,
  }
  props.info.cover = newCover;
};

const handleUploadProgress = (value) => {
  state.value = value.state;
  progress.value = value.progress;
}

const handleUploadStart = (value) => {
  state.value = 2;
  progress.value = 0;
};

const handleNameChange = (value) => {
  const params = {
    characterId: props.info.characterId,
    name: value,
  }
  putCharacterName(params);
  props.info.name = value;
};

const beforePreProcess = async (file) => {
  return new Promise((resolve, reject) => {
    const params = {
      characterId: props.info.characterId,
      mid: file.mid,
      type: 1,
    };
    startAiProcess(params)
      .then(({ success }) => {
        if (success) {
          resolve();
        }
        else {
          reject();
        }
      });
  })
};

onMounted(() => {
  const { info } = props;
  if (info.name) {
    name.value = info.name;
  }
  else {
    inputer.value?.focus();
  }
  if (info.cover) {
    cover.value = info.cover.coverPic;
    state.value = info.cover.state;
  }
});
</script>

<style lang="scss" scoped>
.info-wrapper {
  width: 100%;
}

.character-edit-name {
  & :deep(.el-input__wrapper) {
    padding: 0;
  }
  
  & :deep(.el-input__inner) {
    padding: 0;
    color: #060606;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

    &::placeholder {
      color: #8F959E;
    }
  }
}

.avatar-container {
  position: relative;
  width: 114px;
  height: 114px;
  margin-top: 30px;
}

.upload-avatar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:4px;
  border-radius: 6px;
  border: 1px solid #E5E7EB;
  background: #FFF;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #F4F5F7;
  }
  
  & > span {
    color: #8F959E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.uploaded-avatar {
  width: 100%;
  height: 100%;
  border-radius: 6px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    -webkit-user-drag: none;
  }
}

.avatar-container:hover > .uploaded-avatar-mask {
  opacity: 1;
}

.uploaded-avatar-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:4px;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%);
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  & > span {
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.uploading-avatar-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%);
}

.progress-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  inset: 0;
}

.progress-bar {
  position: relative;
  width: 80%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow-x: hidden;
}

.progress {
  height: 100%;
  background-color: #FFFFFF;
  transition: width 0.2s;
}

.progress-tip {
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  margin-bottom: 4px;
}
</style>