<template>
  <el-dialog
    class="script-broll-material-dialog"
    :model-value="modelValue" 
    :show-close="false"
    :align-center="true"
    :before-close="handleClose"
  >
    <div class="dialog-container">
      <div class="header-wrapper">
        <div class="header-container">
          <span 
          >
            Choose a voice
          </span>
          <icon-button
            name="icon_close"
            color="#646A73"
            :size="20"
            @click="handleClose"
          />
        </div>
      </div>
      <div class="content-wrapper">
        <div class="switcher-wrapper">
          <Switcher 
            v-model="type"
            :tabs="tabs"
            @switch="(v) => type = v"
          />
        </div>
        <div class="scroll-wrapper">
          <el-scrollbar class="scroll-container">
            <el-skeleton animated :loading="currentPagination.loading && currentPagination.page === 0">
              <template #template>
                <div class="skeleton-list">
                  <div class="skeleton-item"  v-for="(_, i) in Array(20)" :key="i" >
                    <el-skeleton-item variant="image" style="width: 100px; height: 100px;border-radius: 4px;" />
                    <div style="display: flex; flex-direction:column; gap: 14px; margin-left: 12px;">
                      <el-skeleton-item variant="rect" style="width: 30px;"/>
                      <el-skeleton-item variant="rect" style="width: 100px;"/>
                    </div>
                  </div>
                </div>      
              </template>
              <div 
                class="voice-list"
                v-infinite-scroll="loadMore"
                :infinite-scroll-distance="50"
                :infinite-scroll-immediate="false"
                :style="{ paddingBottom: currentPagination.loadAll? '180px' : '0'}"
              >
                <div 
                  v-for="item in currentPagination.list"
                  :key="item.mediaId"
                  class="voice-item"
                  :class="{active: voice == item}"
                  @click="voiceClick(item)"
                >
                  <div class="left">
                    <div class="voice-cover-container">
                      <div class="lock-mask" v-if="item.lock">
                        <svg-icon
                          name="icon_lock"
                          :size="14"
                        />
                      </div>
                      <template v-if="type === 0 || (type === 1 && item.url && item.state === 0)">
                        <img
                          alt=""
                          :src="icon_playing"
                          class="icon"
                          @click.stop="pause"
                          v-if="currentAudio.url === item.url && currentAudio.playing"
                        />
                        <img 
                          alt=""
                          :src="icon_play_video"
                          class="icon"
                          @click.stop="playClick(item)"
                          v-else
                        />
                      </template>
                      <img 
                        v-if="item.coverPic"
                        :src="item.coverPic" 
                        :alt="item.name" 
                        class="voice-cover"
                      >
                    </div>
                    <div class="voice-detail">
                      <bv-tip
                        :content="item.name"
                        placement="top"
                      >
                        <p class="voice-name">{{ item.name || "Unnamed" }}</p>
                      </bv-tip>
                      <div class="voice-control" v-if="currentAudio.url === item.url">
                        <p>
                          {{ `${secondsToMs(currentAudio.currentTime)} / ${secondsToMs(currentAudio.audio.duration)}` }}
                        </p>
                        <el-slider
                          @click.stop
                          v-model="currentAudio.currentTime"
                          @change="seekTo"
                          :show-tooltip="false"
                          :step="0.1"
                          :min="0"
                          :max="currentAudio.audio.duration"
                        />
                      </div>
                    </div>

                  </div>
                  <div class="right">
                    <img
                      :src="icon_selected"
                      alt=""
                      v-if="voice == item"
                    />
                    <img
                      :src="icon_select"
                      alt=""
                      v-else
                    />
                  </div>
                </div>
                <div 
                  v-if="type === 1 && currentPagination.list.length === 0" 
                  class="character-empty-container" 
                >
                  <span>No Avatar</span>
                  <common-button @click="createCharacter">Create Avatar</common-button>
                </div>
              </div>
            </el-skeleton>
            <Loading v-show="!currentPagination.loadAll && currentPagination.loading && currentPagination.page > 1"/>
          </el-scrollbar>
        </div>
      </div>
      <div class="footer-wrapper">
        <primary-button 
          size="small"
          :disabled="!voice" 
          @click="replaceClick"
        >
          <span style="width: 108px">
            Replace
          </span>
        </primary-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import Loading from './loading.vue';
import Switcher from '../switcher/switcher.vue';
import { secondsToMs } from '@/utils/util';
import { getSimilarVoiceList } from '@/api/similarVideo';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_playing from '@/assets/similar-video/icon_playing.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
import icon_play_video from '@/assets/similar-video/icon_play_video.svg';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useMessage } from '@/utils';

const message = useMessage();
const modalManager = useModalManager();
const emits = defineEmits([
  "update:modelValue",
  "confirm"
]);
const props = defineProps({
  modelValue: {
    type: Boolean,
  },
});

const tabs = [
  { 
    label: "Library",
    value: 0,
  },
  {
    label: "My Avatars",
    value: 1,
  }
];
const lPagination = reactive({
  page: 0,
  size: 20,
  loading: false,
  loadAll: false,
  list: [],
});
const mPagination = reactive({
  page: 0,
  size: 20,
  loading: false,
  loadAll: false,
  list: [],
});
const type = ref(0);
const voice = ref(null);
const currentAudio = reactive({
  url: null,
  audio: null,
  playing: false,
  loading: true,
  currentTime: 0,
});
const currentPagination = computed(() => {
  if (type.value === 0){
    return lPagination;
  }
  else if (type.value === 1) {
    return mPagination;
  }
  return lPagination;
});

const play = (url) => {
  if (currentAudio.url !== url) {
    destroyAudio();
    const audio = new Audio(url);
    audio.ontimeupdate = () => (currentAudio.currentTime = audio.currentTime);
    audio.oncanplay = () => { 
      currentAudio.loading = false;
    };
    audio.onended = () => {
      currentAudio.playing = false;
      audio.currentTime = 0;
    };

    currentAudio.url = url;
    currentAudio.audio = audio;
  }

  currentAudio.playing = true;
  currentAudio.audio.play();
};

const pause = () => {
  if (!currentAudio.audio) return;
  currentAudio.playing = false;
  currentAudio.audio.pause();
};

const seekTo = (value) => {
  currentAudio.audio.currentTime = value;
};

const destroyAudio = () => {
  if (currentAudio.audio) {
    currentAudio.url = null;
    currentAudio.playing = false;
    currentAudio.audio.ontimeupdate = null;
    currentAudio.audio.onended = null;
    currentAudio.audio.pause();
    currentAudio.audio = null;
    currentAudio.currentTime = 0;
    currentAudio.loading = true;
  }
}

const handleClose = () => {
  emits("update:modelValue", false);
  voice.value = null;
  destroyAudio();
};

const refreshUserList = () => {
  mPagination.page = 0;
  mPagination.loadAll = false;
  mPagination.list = [];
  loadMore();
};

const voiceClick = (value) => {
  if (value.lock) {
    if (props.item.lock) {
      modalManager.applyTemplate("upgradeTips", {
        icon: "orangeWarn",
        title: "Upgrade to edit and use it",
        content: "The character is unavailable, upgrade to unlock this feature.",
      });
      return ;
    };
  }
  if (type.value === 1 && !value.url && value.state === 0) {
    const goToEdit = () => {
      window.open(`/avatar/edit?characterId=${value.mediaId}`, "_blank");
      modalManager.applyTemplate("singleConfirm", {
        icon: "success",
        key: "refresh-character-modal",
        title: "Refresh to continue",
        content: "Once your avatar is created, refresh this page to continue your video creation",
        confirmText: "Refresh",
        onConfirm: () => refreshUserList(),
      });
    };
    message.orangeWarn(
      h("p", { style: "font-size: 14px;font-style: normal;font-weight: 500;line-height: 22px;" },
      [
        h('span', { style: "color: #060606; margin-right: 24px;" }, 'No character voice'),
        h('button', { style: "color: #875EFF", onClick: () => goToEdit() }, 'Go to edit'),
      ]),
    );
    return ;
  }

  destroyAudio();
  if (voice.value === value) {
    voice.value = null;
  }
  else {
    voice.value = value;
  }
};

const playClick = (value) => {
  if (voice.value != value) {
    voice.value = value; 
  }
  play(value.url);
};

const replaceClick = () => {
  emits("confirm", voice.value);
  handleClose();
};

const createCharacter = () => {
  window.open("/avatar", "_blank");

  modalManager.applyTemplate("singleConfirm", {
    icon: "success",
    key: "refresh-character-modal",
    title: "Refresh to continue",
    content: "Once your avatar is created, refresh this page to continue your video creation",
    confirmText: "Refresh",
    onConfirm: () => refreshUserList(),
    zIndex: 5000,
  });
};

const loadMore = async () => {
  if (currentPagination.value.loadAll || currentPagination.value.loading) return;

  currentPagination.value.loading = true;
  const params = {
    size: currentPagination.value.size,
    page: currentPagination.value.page + 1,
    style: 2,
    type: type.value,
  };
  
  const {code, data} = await getSimilarVoiceList(params);
  if (code === 0) {
    if (data.records.length > 0) {
      currentPagination.value.list = [...currentPagination.value.list, ...data.records];
      currentPagination.value.page ++;
      console.log(currentPagination.value.list);
    }
    else if (data.records.length === 0) {
      currentPagination.value.loadAll = true;
    }
  }
  currentPagination.value.loading = false;
}

const setup = async () => {
  await loadMore();
};

watch(
  type,
  () => {
    voice.value = null;
    const { list } = currentPagination.value;
    if (list.length === 0){
      setup();
    }
  },
  {
    immediate: true,
  }
);
</script>

<style>
.script-broll-material-dialog {
  width: 65%;
  height: 80%;
  min-width: 840px;
  min-height: 500px;
  border-radius: 4px;
}

.script-broll-material-dialog .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 100%;
}
.script-broll-material-dialog .el-dialog__header{
  padding: 0;
  margin: 0;  
}
</style>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & :deep(.bv-tooltip-content-container) {
    width: fit-content; 
    height: fit-content;
  }
}
.header-wrapper {
  flex: 0 0;
  padding: 20px 46px 24px;;
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}

.switcher-wrapper {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.content-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.footer-wrapper {
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
}

.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;

  & .stepback {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.character-empty-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > span {
    color: #646A73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    margin-bottom: 24px;
  }

  & :deep(.el-button) {
    padding: 10px 36px;
    color: #060606;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.scroll-wrapper {
  position: relative;
  height: calc(100% - 52px);
  padding: 0 5px;
}

.scroll-container {
  padding: 0 24px 0;
}

.skeleton-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
  grid-auto-rows: minmax(124px, 124px);
  gap: 12px;
}

.skeleton-item {
  display: flex;
}

.voice-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(376px, 1fr));
  grid-auto-rows: minmax(124px, 124px);
}

.voice-item {
  padding: 12px 17px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;

  &.active,
  &:hover {
    background: #F8F5FF;
  }

  & > .left {
    display: flex;

  }

  & .voice-cover-container {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    background: #EBEDEF;
    margin-right: 12px;

    & > .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
    }
  }

  & .lock-mask {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.80);
    padding: 6px;
    border-radius: 4px;
    color: #ffe4ae;
  }

  & .voice-cover {
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }

  & .voice-name {
    max-width: 124px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  & .voice-detail {
    height: 100%;
  }

  & .voice-control {
    margin-top: auto;
  }

  & :deep(.el-slider) {
    width: 187px;
  }

  & :deep(.el-slider__runway) {
    height: 4px;
    background: #e5e7eb;
  }

  & :deep(.el-slider__bar) {
    height: 4px;
  }

  & :deep(.el-slider__button) {
    border: 1px solid #e5e7eb;
    transition: none;
    width: 14px;
    height: 14px;
    box-shadow: 2px 2px 4px #eae8e8;
    transform: none;
    &:hover {
      width: 14px;
      height: 14px;
      transform: none;
    }
  }

  & :deep(.el-slider__button-wrapper) {
    top: -16px;
    &:hover {
      :deep(.el-slider__button) {
        transform: none;
      }
    }
  }
}
</style>