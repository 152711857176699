<template>
  <div class="pexels-container">
    <div class="search-container">
      <el-input
        v-model="keyword"
        placeholder="Search for free materials"
        @keyup.enter="search"
        @update:model-value="updateKeyword"
      >
        <template #prefix>
          <el-dropdown
            trigger="click"
            popper-class="script-type-popper"
            :teleported="true"
            :popper-options="{
              modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
            }"
            @command="command"
          >
            <div class="type-select">
              <span>{{ currentType }}</span>
              <svg-icon name="icon_left" class="icon_left" :size="16" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in typeOptions"
                  :key="item"
                  :command="item"
                >
                  <span>{{ item }}</span>
                  <svg-icon
                    name="icon_select"
                    :size="16"
                    v-if="currentType === item"
                    style="color: #875eff"
                  />
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
        <template #suffix>
          <svg-icon
            name="editor_library_search"
            :size="18"
            clickable
            @click="search"
          />
        </template>
      </el-input>
      <el-popover
        ref="popover"
        placement="bottom-end"
        trigger="click"
        :show-arrow="false"
        :width="230"
        :hide-after="0"
        :teleported="false"
        :disabled="keyword.length === 0"
      >
        <template #reference>
          <icon-button
            border
            name="editor_library_filter"
            :size="18"
            :disabled="keyword.length === 0"
            :active="!!ratio || !!color"
            :color="!!ratio || !!color ? '#FFFFFF' : '#646A73'"
          />
        </template>
        <div class="filter-title">Ratio</div>
        <div class="ratio-list">
          <div
            class="ratio-option"
            v-for="(option, i) in ratioOptions"
            :key="i"
            :class="{ active: option === ratio }"
            @click="clickRatio(option)"
          >
            <div :class="option"></div>
          </div>
        </div>
        <div class="filter-title" v-if="showColor">Color</div>
        <div class="color-list" v-if="showColor">
          <div v-for="(col, i) in colorOptions" class="color-col" :key="i">
            <div
              v-for="(option, j) in col"
              class="color-option"
              :key="j"
              :style="{ backgroundColor: option }"
              @click="clickColor(option)"
            >
              <svg-icon
                v-show="option === color"
                name="editor_library_checked"
                :size="22"
              />
            </div>
          </div>
        </div>
        <div class="buttons">
          <ordinary-plain-button
            :disabled="!ratio && !color"
            size="extra-small"
            @click="reset"
            >Reset</ordinary-plain-button
          >
          <primary-button size="extra-small" @click="popover?.hide()"
            >Done</primary-button
          >
        </div>
      </el-popover>
    </div>
    <div class="tag-list">
      <span
        v-for="(option, i) in tagOptions"
        :key="i"
        class="tag"
        :class="{ active: option === keyword }"
        @click="clickTag(option)"
        >{{ option }}</span
      >
    </div>
    <el-scrollbar>
      <el-skeleton animated :loading="loading && page === 1">
        <template #template>
          <el-skeleton-item
            v-for="(_, i) in Array(40)"
            variant="rect"
            :key="i"
          />
        </template>
        <empty v-if="empty" />
        <div v-else class="list-wrapper">
          <div
            ref="list"
            class="file-list"
            v-infinite-scroll="getList"
            :infinite-scroll-immediate="false"
            :infinite-scroll-distance="50"
            :infinite-scroll-disabled="disabled"
          >
            <div
              v-for="(file, i) in files"
              class="file-item"
              :key="i"
              :data-width="file.width"
              :data-height="file.height"
              @mouseenter="hoverFile = file"
              @mouseleave="hoverFile = null"
              @click="clickFile(file)"
            >
              <div class="image-wrapper">
                <img :src="file.coverPic" loading="lazy" draggable="false" />
              </div>
              <span v-show="selectFile === file" class="select-mask">
                <svg-icon name="script_select_file" :size="24" />
              </span>
              <span
                v-if="file.duration"
                v-show="hoverFile !== file"
                class="duration"
              >
                {{ secondsToHms(file.duration) }}
              </span>
              <span
                v-show="hoverFile === file"
                class="preview"
                @mousedown.stop="preview(file)"
              >
                <svg-icon name="editor_library_preview" clickable :size="18" />
              </span>
            </div>
          </div>
          <loading v-show="loading && page > 1" />
        </div>
        <previewer
          ref="previewer"
          :showArrow="false"
          :record="file"
          :zIndex="3000"
        />
      </el-skeleton>
    </el-scrollbar>
  </div>
</template>

<script setup>
import Empty from "./empty.vue";
import Loading from "./loading.vue";
import Previewer from "../material-previewer/index.vue";
import { secondsToHms } from "../../utils";
import { getImageList, getVideoList } from "@/api/library";

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
  currentTab: {
    type: String,
    default: "Library",
  },
  keyword: {
    type: String,
    default: "",
  },
});

const popover = ref(null);
const previewer = ref(null);
const keyword = ref(props.keyword);
const currentType = ref("Videos");
const hoverFile = ref(null);
const selectFile = ref(props.modelValue);
const ratio = ref("");
const color = ref("");
const loading = ref(false);
const page = ref(1);
const total = ref(0);
const list = ref(null);
const files = ref([]);
const file = ref({});
const ro = ref(null);
const empty = computed(() => files.value.length === 0);
const disabled = computed(
  () => loading.value || (total.value > 0 && files.value.length >= total.value)
);
const showColor = computed(() => currentType.value === "Photos");

const typeOptions = ["Videos", "Photos"];
const tagOptions = [
  "Architecture",
  "Interiors",
  "Textures",
  "Patterns",
  "Nature",
  "Cozy",
];
const ratioOptions = ["portrait", "landscape", "square"];
const colorOptions = [
  ["#FFFFFF", "#CCCCCC", "#999999", "#666666", "#323333", "#000001"],
  ["#F9B8BA", "#F57A7B", "#F03D3D", "#D91012", "#9C0A0D", "#5F0708"],
  ["#F9DDB8", "#F5BD7C", "#F09F3D", "#DA8013", "#9D5B0F", "#5E3708"],
  ["#FAF6B8", "#F4ED7B", "#F0E73D", "#D9CE15", "#9B950D", "#5F590A"],
  ["#CFF9B9", "#A6F47A", "#7BF03D", "#57DA10", "#3F9C0C", "#265E06"],
  ["#B9F9E7", "#7BF5D6", "#3EEFC3", "#18D8A7", "#089C78", "#085E49"],
  ["#B9E5F9", "#7AD0F4", "#3FBAEE", "#0D9DD9", "#0B719C", "#07145F"],
  ["#B8C1F9", "#AA7BF4", "#853EF0", "#6112DA", "#450C9C", "#2A075E"],
  ["#F6B9FA", "#EE7BF3", "#E73EEF", "#CE12D8", "#950C9B", "#5A085E"],
];
const funcMap = {
  Photos: getImageList,
  Videos: getVideoList,
};

onMounted(() => {
  const dialog = document.querySelector(".bv-dialog");

  if (dialog) {
    ro.value = new ResizeObserver(resize);
    ro.value.observe(dialog);
  }
});
onBeforeUnmount(() => {
  if (ro.value) {
    ro.value.disconnect();
  }
  ro.value = null;
});

watch(
  () => props.modelValue,
  (value) => (selectFile.value = value)
);
watch(
  () => props.keyword,
  (value) => {
    keyword.value = value;
    search();
  }
);
watch(currentType, search);
watch([files, () => props.currentTab], resize, {
  flush: "post",
  immediate: true,
});

function resize() {
  if (props.currentTab !== "Library") {
    return;
  }
  const dialog = document.querySelector(".bv-dialog");
  if (!empty.value && list.value && dialog && dialog.clientWidth > 0) {
    let columns = 10;
    let width = 0;
    const gap = 12;
    const padding = 60;
    const MIN_WIDTH = 107;

    while (
      (width =
        (dialog.clientWidth - gap * (columns - 1) - padding * 2) / columns) <
      MIN_WIDTH
    ) {
      columns--;
    }
    const hs = new Array(columns).fill(0);

    for (const element of list.value.children) {
      const w = parseFloat(element.dataset.width);
      const h = parseFloat(element.dataset.height);
      const height = h * (width / w);
      const index = hs.indexOf(Math.min(...hs));
      const left = (width + gap) * index;
      const top = hs[index];

      element.style.left = `${left}px`;
      element.style.top = `${top}px`;
      element.style.width = `${width}px`;
      element.style.height = `${height}px`;

      hs[index] += height + gap;
    }
    list.value.style.height = `${Math.max(...hs)}px`;
  }
  if (
    (!list.value ||
      list.value.scrollHeight <
        list.value.parentElement.parentElement.parentElement.clientHeight) &&
    !disabled.value
  ) {
    getList();
  }
}

const command = (value) => {
  currentType.value = value;
};

function updateKeyword(value) {
  keyword.value = value;

  if (value === "") {
    ratio.value = "";
    color.value = "";
  }
}

function clickFile(file) {
  if (selectFile.value === file) {
    selectFile.value = null;
    return;
  }

  selectFile.value = file;
  const typeMap = {
    Photos: "image",
    Videos: "video",
  };
  file.type = typeMap[currentType.value];
  emits("update:modelValue", file);
}

function clickTag(tag) {
  keyword.value = tag;
  search();
}

function clickRatio(value) {
  ratio.value = value === ratio.value ? "" : value;
  search();
}

function clickColor(value) {
  color.value = value === color.value ? "" : value;
  search();
}

function reset() {
  ratio.value = "";
  color.value = "";
  search();
}

async function getList() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  const data = { page: page.value, size: 40 };

  if (keyword.value) {
    data.keyword = keyword.value;
  }
  if (ratio.value) {
    data.shape = ratio.value;
  }
  if (color.value && currentType.value === "Photos") {
    data.color = color.value;
  }

  const type = currentType.value;
  const response = await funcMap[type](data);

  if (response.success) {
    const records = response.data.records;
    total.value = response.data.total;

    if (records.length > 0) {
      files.value = files.value.concat(records);
      page.value++;
    }
  }
  loading.value = false;
}

function search() {
  files.value = [];
  page.value = 1;
  getList();
}

function preview(value) {
  const typeMap = {
    Photos: "image",
    Videos: "video",
  };
  const type = currentType.value;
  file.value = { ...value, type: typeMap[type] };
  previewer.value.open = true;
}
</script>
<style>
.script-type-popper {
  width: 92px;
}

.script-type-popper .el-popper__arrow {
  display: none;
}

.script-type-popper .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px;
  color: #060606;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
</style>
<style lang="scss" scoped>
:deep(.el-scrollbar) {
  width: 100%;
  height: calc(100% - 108px);
}
.pexels-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
}

.type-select {
  width: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #1c1b1e;

  & > span {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  & > svg {
    transform: rotate(-90deg);
    color: #bbbfc4;
  }
}

.search-container {
  display: flex;
  align-items: center;
  height: 38px;
}

:deep(.search-container .el-input) {
  height: 36px;
  width: 411px;
}

:deep(.search-container .el-input__wrapper) {
  padding: 4px 12px 4px 4px;
  border-color: #d5d6d7;
}

:deep(.search-container .el-input__inner) {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

:deep(.icon-button.border) {
  margin-left: 12px;
  border-radius: 4px;
}

:deep(.icon-button.border .icon-wapper) {
  padding: 9px;
}
:deep(.icon-button.active) {
  border: 1px solid #fff;
  background-color: #875eff;
}

:deep(.el-popover.el-popper) {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.08);
  padding: 12px 12px 20px;
}
.filter-title {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.ratio-list {
  margin: 10px 0 20px;
  display: flex;
  justify-content: space-between;
}
.ratio-option {
  width: 64px;
  height: 38px;
  border-radius: 2px;
  border: 0.5px solid #e5e7eb;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
}
.ratio-option.active {
  border-color: #6741ff;
  background-color: #f8f5ff;
}
.ratio-option div {
  background-color: #fff;
  border-radius: 2px;
  border: 0.5px solid #646a73;
  transition: border-color 200ms;
}
.ratio-option.active div {
  border-color: #6741ff;
  background-color: #f8f5ff;
}
.ratio-option .landscape {
  width: 30px;
  height: 18px;
}
.ratio-option .portrait {
  width: 18px;
  height: 30px;
}
.ratio-option .square {
  width: 24px;
  height: 24px;
}
.color-list {
  display: flex;
  margin-top: 5px;
}
.color-col + .color-col {
  margin-left: 1px;
}
.color-option {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.color-option + .color-option {
  margin-top: 1px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
:deep(.buttons .el-button) {
  padding: 0;
  width: 94px;
  height: 36px;
}
.tag-list {
  height: 70px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 60px 18px;
  gap: 6px;
}
.tag {
  padding: 4px 7px;
  border-radius: 2px;
  background-color: #ebedef;
  color: #646a73;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  transition: all 200ms;
}

.tag.active {
  background-color: #646a73;
  color: #f8f5ff;
}

.search-container > .icon-button.border {
  border-width: 1px;
}

:deep(.el-skeleton) {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(107px, 1fr));
  padding: 0 60px;
}
.image-wrapper,
:deep(.el-skeleton__item) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f3f5f7;
  border-radius: 4px;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}

:deep(.el-skeleton__item) {
  height: 70px;
}
.list-wrapper {
  padding: 0 60px;
}
.file-list {
  position: relative;
}

.file-item {
  position: absolute;
}

.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-item .duration {
  padding: 0 8px;
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  right: 4px;
  bottom: 4px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.file-item .preview {
  padding: 3px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  border-radius: 2px;
  background-color: rgba(38, 38, 38, 0.7);
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%);
}
.empty p {
  color: #646a73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: -25px;
}

.select-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
