<template>
  <div ref="container" class="previewer-container">
    <div class="absolute top-10 right-10">
      <bv-select
        v-model="currentVersion"
        :selectedId="currentVersion"
        :isScroll="true"
        :options="versionsList"
        justifyAlign="end"
        width="148px"
        :showSearch="false"
        placement="bottom-start"
        @change="handleHistoryChange"
        v-if="versionsList.length > 1"
        :showBorder="false"
        :popperStyle="{ marginTop: '6px' }"
        :textStyle="{ textAlign: 'right', paddingRight: '10px' }"
      />
    </div>

    <div
      class="preview-container flex justify-center items-center h-full"
      :style="computedPadding"
    >
      <!-- 上一页 -->
      <div class="mr-4">
        <svg
          width="66"
          height="66"
          viewBox="0 0 66 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer"
          @click="handlePre"
          @mousemove="hoverPreBtn"
          @mouseleave="leavePreBtn"
        >
          <circle cx="33" cy="33" r="32.5" :fill="preColor" stroke="#BBBFC4" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.5303 26.9697C36.8232 27.2626 36.8232 27.7374 36.5303 28.0303L31.0607 33.5L36.5303 38.9697C36.8232 39.2626 36.8232 39.7374 36.5303 40.0303C36.2374 40.3232 35.7626 40.3232 35.4697 40.0303L29.4697 34.0303C29.1768 33.7374 29.1768 33.2626 29.4697 32.9697L35.4697 26.9697C35.7626 26.6768 36.2374 26.6768 36.5303 26.9697Z"
            :fill="preArrowFill"
          />
        </svg>
      </div>

      <div
        class="carousel__container"
        id="carousel__container"
        ref="carouselContainer"
        :style="{ width: carouselContainerWidth + '%' }"
      >
        <Carousel
          v-bind="settings"
          :breakpoints="breakpoints"
          ref="carousel"
          class="carousel-box"
          @init="handleCarouseInit"
          @updateSlideWidth="handleCarouseUpdate"
        >
          <Slide
            v-for="(item, index) in skeletonList"
            ref="slideItem"
            v-if="!imageList.length"
          >
            <renderItem
              :item="item"
              :index="index"
              :ratio="ratio"
              :length="skeletonList.length"
              class="slide-item"
              :style="{
                aspectRatio:
                  ratio == '1:1' ? '1/1' : ratio == '16:9' ? '16/9' : '9/16',
              }"
            />
          </Slide>

          <Slide v-for="(item, index) in imageList" :key="item.rid" v-else>
            <renderItem
              :item="item"
              :index="index"
              :ratio="ratio"
              class="slide-item"
              :length="imageList.length"
              :loading="loading"
              :request="requestTemplateList"
              @onIsPlaying="handleItemIsPlaying"
              @goBackToFirstPlay="handleAoBackToFirstPlay"
              :style="{
                aspectRatio:
                  ratio == '1:1' ? '1/1' : ratio == '16:9' ? '16/9' : '9/16',
              }"
            />
          </Slide>

          <template #addons>
            <Pagination ref="pagination" v-if="showButton" />
          </template>
        </Carousel>
      </div>

      <!-- 下一页 -->
      <div @mousemove="hoverNextBtn" @mouseleave="leaveNextBtn" class="ml-4">
        <svg
          width="66"
          height="66"
          viewBox="0 0 66 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer ml-1"
          @click="handleNext"
        >
          <circle
            cx="33"
            cy="33"
            r="32.5"
            transform="matrix(-1 0 0 1 66 0)"
            :fill="nextColor"
            stroke="#BBBFC4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.4697 26.9697C29.1768 27.2626 29.1768 27.7374 29.4697 28.0303L34.9393 33.5L29.4697 38.9697C29.1768 39.2626 29.1768 39.7374 29.4697 40.0303C29.7626 40.3232 30.2374 40.3232 30.5303 40.0303L36.5303 34.0303C36.8232 33.7374 36.8232 33.2626 36.5303 32.9697L30.5303 26.9697C30.2374 26.6768 29.7626 26.6768 29.4697 26.9697Z"
            :fill="nextArrowFill"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { reactive, ref, computed, onMounted, watch } from "vue";

import renderItem from "./components/renderItem.vue";
import "@/components/common/bv-carousel/styles/index";
import {
  Carousel,
  Slide,
  Pagination,
} from "@/components/common/bv-carousel/index";

import { getRenderInfos, versionList, reGenerate } from "@/api/index";
import { useSingleMessage } from "@/utils";
import type { templateInfo } from "./type";
import { setItem } from "@/utils/storage";
import { useTrackStore } from "@/store/modules/track";

type VersionType = {
  value: number;
  label: string;
};
const route = useRoute();
const { collectData, track } = useTrackStore();
const carousel = ref(null);

const container: any = ref(null);
const carouselContainer: any = ref(null);
const windowWidth = ref(0);
const windowHeight = ref(0);

const computedPadding = ref({});
const currentVersion = ref(1);
const reqId = ref<string | null>(null);
const ratio = ref("");

const versionsList = ref<VersionType[]>([]);
const skeletonList = ref([{}, {}, {}, {}]);
const imageList = ref<templateInfo[]>([]);
const pagination = ref(null);
const Message = useSingleMessage();
const handlerMessage = ref(null);
const loading = ref(false);
const carouselConfigData = ref({});

// 分页
const currentPage = ref(0);
const slideCount = ref(0);
const minSlideCount = ref(0);
const itemsToShow = ref(3);
const showButton = ref(false);
const slideItem = ref(null);

const isNextHover = ref(false);
const isPreHover = ref(false);
const carouselContainerWidth = ref(80);

// carousel配置
const settings = reactive({
  itemsToShow: 3,
  snapAlign: "start",
});

const breakpoints = reactive({
  200: {
    itemsToShow: 1,
    snapAlign: "center",
    itemsToScroll: 1,
  },
  800: {
    itemsToShow: 2,
    snapAlign: "start",
    itemsToScroll: 2,
  },

  1350: {
    itemsToShow: 3,
    snapAlign: "start",
    itemsToScroll: 3,
  },

  1400: {
    itemsToShow: 3,
    snapAlign: "start",
    itemsToScroll: 3,
  },

  1600: {
    itemsToShow: 4,
    snapAlign: "start",
    itemsToScroll: 4,
  },
});
watch(
  () => ratio.value,
  (ratio) => {
    if (ratio == "16:9") {
      settings.itemsToShow = 2;
      breakpoints[800] = {
        itemsToShow: 1,
        snapAlign: "start",
        itemsToScroll: 1,
      };

      breakpoints[1350] = {
        itemsToShow: 1,
        snapAlign: "start",
        itemsToScroll: 1,
      };

      breakpoints[1400] = {
        itemsToShow: 2,
        snapAlign: "start",
        itemsToScroll: 2,
      };

      breakpoints[1600] = {
        itemsToShow: 2,
        snapAlign: "start",
        itemsToScroll: 2,
      };
    }
  },
  {
    immediate: true,
  }
);

const isEnd = computed(() => {
  return currentPage.value + itemsToShow.value === slideCount.value;
});

const isStart = computed(() => {
  return currentPage.value === 0;
});

const preColor = computed(() => {
  return isPreHover.value && !isStart.value ? "#EBEDEF" : "#FFF";
});

const nextColor = computed(() => {
  return isNextHover.value && !isEnd.value ? "#EBEDEF" : "#FFF";
});

const preArrowFill = computed(() => {
  return currentPage.value === 0 ? "#BBBFC4" : "#1C1B1E";
});

const nextArrowFill = computed(() => {
  return currentPage.value + itemsToShow.value === slideCount.value
    ? "#BBBFC4"
    : "#1C1B1E";
});

const handleCarouseInit = () => {
  carouselConfigData.value = carousel.value?.data;
  if (!carousel.value?.data) {
    return;
  }
  const {
    currentSlide = 0,
    slidesCount,
    minSlide,
    config,
  } = carousel.value?.data;
  currentPage.value = currentSlide.value;
  slideCount.value = slidesCount.value;
  minSlideCount.value = minSlide.value;
  itemsToShow.value = config.itemsToShow;
  showButton.value = true;
  // 解决一些意外情况，slideCount计算不正确
  setTimeout(() => {
    handleCarouseInit();
  }, 1000);
};

const handleCarouseUpdate = () => {
  carouselConfigData.value = carousel.value?.data;
  const { currentSlide, slidesCount, minSlide, config } = carousel.value?.data;
  currentPage.value = currentSlide.value;
  slideCount.value = slidesCount.value;
  minSlideCount.value = minSlide.value;
  itemsToShow.value = config.itemsToShow;
};

const resetCarouse = () => {
  currentPage.value = 0;
  slideCount.value = 0;
  minSlideCount.value = 0;
  itemsToShow.value = 3;
};

const handlePre = () => {
  if (loading.value) {
    return;
  }

  carousel.value?.prev();
};

const handleNext = () => {
  if (loading.value) {
    return;
  }

  carousel.value?.next();
};

const hoverNextBtn = () => {
  isNextHover.value = true;
};

const leaveNextBtn = () => {
  isNextHover.value = false;
};

const hoverPreBtn = () => {
  isPreHover.value = true;
};

const leavePreBtn = () => {
  isPreHover.value = false;
};

const updateWindowWidth = () => {
  const rect = container.value?.getBoundingClientRect();
  if (rect?.width / 1440 < 0.7) {
    // 单个屏
    carouselContainerWidth.value = (80 * rect?.width) / 1440;
  } else if (rect?.width / 1440 < 1 || rect?.height / 900 < 0.8) {
    carouselContainerWidth.value = ((80 * rect?.width) / 1440) * 0.7;
  } else if (
    (rect?.width / 1440 > 1 && rect?.width / 1440 < 1.2) ||
    (rect?.height / 900 > 1.1 && rect?.height / 900 < 1.2)
  ) {
    carouselContainerWidth.value = ((80 * rect?.width) / 1440) * 0.85;
  } else {
    carouselContainerWidth.value = 75;
  }

  windowWidth.value = rect?.width;
  windowHeight.value = rect?.height;
  // 手动更新分页数据
  carousel.value?.updateSlidesData();
};

const initAssets = (assets: EditableAssetItem[]) => {
  const newAsset = assets.map((asset: EditableAssetItem) => {
    return {
      ...asset,
      cropUrl: asset.material,
      layerId: "",
      highLight: false,
    };
  });
  return newAsset || [];
};

const handleImageList = (data: templateInfo[]) => {
  data.forEach((item) => {
    let editAssets: EditableAssetItem[] = [];
    if (item.assets && item.assets.length) {
      editAssets = initAssets(item.assets!);
    }
    const newItem: templateInfo = {
      rid: item.rid,
      type: item.type,
      reqId: item.reqId,
      templateUrl: item.templateUrl,
      templateId: item.templateId || 0,
      scriptId: item.scriptId || 0,
      formattingId: item.formattingId || [],
      musicId: item.musicId || [],
      filterId: item.filterId || [],
      editAssets: editAssets,
      renderConfig: item.renderConfig,
      ratio: item.ratio,
      tags: item.tags,
      lever: item.lever || "",
      isFirstPlay: true,
      draftId: item.draftId,
    };
    imageList.value.push(newItem);
  });
};

// 重新生成
const requestTemplateList = async () => {
  // 重置 imageList
  imageList.value = [];
  resetCarouse();
  loading.value = true;

  try {
    const params: CreateVideoReqParams.GenerateVideoParams = {
      prompt: "",
      reqId: route.query.reqId as string,
    };
    const { data } = await reGenerate(params);
    handleImageList(data);

    imageList.value.push({
      ratio: "9:16",
      rid: 9,
      type: "button",
      tags: ["button"],
    });

    await getVersionList(reqId.value!);
    currentVersion.value = data && data[0] && data[0].version;
    loading.value = false;
    handlerMessage.value?.close();

    if (!pagination.value) {
      return;
    }

    pagination.value?.isActive(1);
  } catch (err) {
    handlerMessage.value?.close();
    loading.value = false;
  }
};

// 根据版本列表
const getVersionList = async (id: string) => {
  try {
    const { data } = await versionList(id);
    const { versions } = data;

    versionsList.value = versions.map((version) => {
      return {
        value: version,
        label: "History" + version,
      };
    });
  } catch (err) {
    console.log("err");
  }
};

const handleHistoryChange = (value: string) => {
  resetCarouse();
  const target = versionsList.value.find(
    (version: VersionType) => version.value == value
  );
  if (target) {
    getAssetInfo(reqId.value!, target.value!);
  }
};

// 根据版本获取渲染包和素材资源
const getAssetInfo = async (reqId: string, version: number = 1) => {
  imageList.value = [];

  try {
    const { data } = await getRenderInfos(reqId, version);
    currentVersion.value = data && data[0] && data[0].version;
    handleImageList(data);
    getVersionList(reqId);

    imageList.value.push({
      ratio: "",
      rid: 9,
      type: "button",
      tags: ["button"],
    });
  } catch (err) {
    Message.error("error,  Please try agin", { duration: 3000 });
  }
};

const handleItemIsPlaying = (rid: string, isPlaying: boolean) => {
  if (!isPlaying) {
    return;
  }

  imageList.value.forEach((item) => {
    if (item.rid == rid) {
      item.isPlaying = true;
      item.isFirstPlay = false;
    } else {
      item.isPlaying = false;
      item.isFirstPlay = true;
    }
  });
};

// 停止播放回到初始状态
const handleAoBackToFirstPlay = (rid: string) => {
  imageList.value.forEach((item) => {
    if (item.rid == rid) {
      item.isPlaying = false;
      item.isFirstPlay = true;
    }
  });
};

onMounted(async () => {
  const id = route.query?.reqId as string | null;

  const templateRatio = route.query?.ratio as string;
  const type = route.query?.videoType as string;
  reqId.value = id;
  ratio.value = templateRatio;

  collectData("boolvideo_video_view", {
    req_id: id,
    video_type: type,
  });
  track("boolvideo_video_view");
  setItem("reqId", reqId.value);
  setItem("ratio", ratio.value);

  if (id) {
    getAssetInfo(id, currentVersion.value);
  }

  updateWindowWidth();

  window.onresize = () => {
    updateWindowWidth();
  };
});
</script>

<style lang="scss" scoped>
.previewer-container {
  height: 100%;
  min-width: 400px;
  min-height: fit-content;
  overflow: scroll;
  position: relative;
}

.carousel__container {
  height: 100%;
  min-width: 300px;

  & > .carousel-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 32px;

    & > :deep(.carousel__liveregion) {
      display: none;
    }

    & > :deep(.carousel__viewport) {
      display: flex;
      align-items: center;
      flex: 1;
      height: auto;
      overflow: clip visible;
    }
  }
}

:deep(.el-loading-spinner .path) {
  stroke: #646a73 !important;
}

.carousel__pagination {
  margin-top: 0;
  padding-block: 20px 54px;
}
</style>
