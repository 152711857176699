<script setup>
import { onBeforeMount, ref, onMounted } from 'vue';
import { useHead } from '@vueuse/head';

import { BvCard } from '@/components/common/index';
import {
  icon_idea_video,
  icon_product_video,
  icon_script_video,
  icon_blog_video,
  icon_template_video,
  icon_visual_video,
  icon_scratch,
} from '@/assets/icons/workspace/index';

import { useTrackStore } from '@/store/modules/track';
import { caseList } from './data.js';
import UserCardList from './UserCardList.vue';
import { usePointerMove } from '@/utils/hook';
import precisoTrackEvent from '@/utils/precisoTrackEvent.ts';
import { useUserStore, useSubscriptionInfo } from '@/store/modules/user';
import { removeItem, sessionRemoveItem , sessionSetItem} from '@/utils/storage';

import similar_video_case_1 from '@/assets/similar-video/similar_video_case_1.png';
import similar_video_case_2 from '@/assets/similar-video/similar_video_case_2.png';
import home_arrow from '@/assets/similar-video/home_arrow.svg';
import home_button_arrow from '@/assets/similar-video/home_button_arrow.svg';

import { useSimilarVideo } from '@/pages/similarVideo/store/index.js';

const globalDomain = inject('globalDomain');
const similarVideoStore = useSimilarVideo();
const {} = storeToRefs(similarVideoStore);

const { collectData, clearEventData, track } = useTrackStore();
const { packageType } = useSubscriptionInfo();
const route = useRoute();
const router = useRouter();
const scrollRef = ref(null);
const prePosition = ref(null);
const caseInfoList = ref(caseList);
const isDragging = ref(false);
const startX = ref(0);
const scrollLeft = ref(0);
const transformStyle = ref(null);
const imageList = ref([
  icon_idea_video,
  icon_product_video,
  icon_script_video,
  icon_blog_video,
  icon_template_video,
  icon_visual_video,
  icon_scratch,
]);

onMounted(() => {
  if (globalDomain == 1 && import.meta.env.VITE_ENV == 'prod') {
    precisoTrackEvent({
      Currency: '',
      CustomerType: packageType == 'FREE' ? 'Free' : 'Subscribed',
      ProductId: 3,
      ProductCategoryList: '',
      CouponCode: '',
      PageType: 'product',
    });
    return;
  }
});

const pointerMoveConfig = {
  handler: (e) => {
    if (e.state !== 'move') return;
    if (!isDragging.value) {
      e.abort();
      return;
    }

    Object.assign(position, {
      left: position.left + e.deltaX,
      top: position.top + e.deltaY,
    });
  },
};

const pointerMove = usePointerMove(pointerMoveConfig);

useHead({
  title: route.meta.title,
  meta: [
    {
      property: 'og:title',
      content: route.meta.title,
    },
    {
      name: 'twitter:title',
      content: route.meta.title,
    },
  ],
});

const handleTemplateToVideo = () => {
  router.push({
    path: '/template-video-list',
  });
};

const handleProductToVideo = () => {
  router.push({
    path: '/product-to-video',
  });
};

const handleIdeaToVideo = () => {
  router.push({
    path: '/idea-to-video',
  });
};

const handleVideoEditor = async () => {
  clearEventData('boolvideo_export_click');
  router.push({
    path: '/editor',
  });
};

const handleScriptToVideo = () => {
  router.push({
    path: '/script-to-video',
  });
};

const handleBlogToVideo = () => {
  router.push({
    path: '/blog-to-video',
  });
};

const handleVisualsToVideo = () => {
  router.push({
    path: '/visuals-to-video',
  });
};

const cardList = [
  {
    id: 1,
    title: 'Product to Video',
    iconName: icon_product_video,
    describe: 'URL or image directly to video, customize it as you wish',
    clickEvent: handleProductToVideo,
    trackName: 'product_to_video',
    isBeta: false,
  },
  {
    id: 2,
    title: 'Idea to Video',
    iconName: icon_idea_video,
    describe: 'Transform your ideas into stunning videos with AI voices',
    clickEvent: handleIdeaToVideo,
    trackName: 'idea_to_video',
    isBeta: false,
  },
  {
    id: 3,
    title: 'Script to Video',
    iconName: icon_script_video,
    describe: 'Turn your written scripts into engaging videos',
    clickEvent: handleScriptToVideo,
    trackName: 'script_to_video',
  },
  {
    id: 4,
    title: 'Blog to Video',
    iconName: icon_blog_video,
    describe: 'Convert your blog URL into engaging videos',
    clickEvent: handleBlogToVideo,
    trackName: 'blog_to_video',
  },
  {
    id: 5,
    title: 'Visuals to Video',
    iconName: icon_visual_video,
    describe: 'Transform images or video clips into dynamic videos',
    clickEvent: handleVisualsToVideo,
    trackName: 'visuals_to_video',
  },
  {
    id: 6,
    title: 'Templates to Video',
    iconName: icon_template_video,
    describe: 'Create videos with stunning templates',
    clickEvent: handleTemplateToVideo,
    trackName: 'templates_to_video',
    isBeta: false,
  },
  {
    id: 7,
    title: 'Start from Scratch',
    iconName: icon_scratch,
    describe: 'Start creating videos with our best online editor',
    clickEvent: handleVideoEditor,
    trackName: 'editor',
    isBeta: false,
  },
];

const handleShowLearn = () => {
  window.open(
    'https://boolv.notion.site/34b0bb1a2931464da05ba2df1475b56c?v=fe0a1405f2b441e7a2e270fc6392e9a4'
  );
};

const handleClick = (item) => {
  collectData('boolvideo_page_click', {
    click: item.trackName,
  });
  track('boolvideo_page_click');
  const voiceoverFuncs = ['idea_to_video', 'script_to_video', 'blog_to_video'];
  if (voiceoverFuncs.includes(item.trackName)) {
    collectData('boolvideo_text_input', {
      video_type: item.trackName,
    });
  }
  item.clickEvent();

  sessionSetItem('create_from', 'workspace')
};

const handleScroll = (position) => {
  // 6164.5
  if (prePosition.value?.scrollLeft > position.scrollLeft) {
    // 右滑
    if (position.scrollLeft < 150) {
      scrollRef.value?.scrollTo(0, 1);
    }
  }
  prePosition.value = position;
};

const handleIsPlaying = (item) => {
  caseInfoList.value?.forEach((e) => {
    if (e.id == item.id) {
      e.isPlaying = item.isPlaying;
    } else {
      if (item.isPlaying) {
        e.isPlaying = false;
      }
    }
  });
};

onBeforeMount(() => {
  similarVideoStore.removeSimilarVideoCache();
  sessionRemoveItem('cacheForm');
  sessionRemoveItem('similarVideo');
  imageList.value.forEach((src) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      console.log('预加载');
    };
  });

  caseInfoList.value.forEach((url) => {
    let coverImage = new Image();
    coverImage.src = url;
    coverImage.onload = () => {
      console.log('预加载');
    };
  });
});

const handleToSimilarVideo = () => {
  router.push('/similar-video');
};
</script>

<template>
  <el-scrollbar>
    <div class="workspace-page">
      <div class="learn-btn-box flex justify-end">
        <div
          class="flex justify-center items-center learn-btn"
          @click="handleShowLearn"
        >
          <svg-icon name="icon_light" :size="20"></svg-icon>
          <p class="tip-content text-titleColor text-sm">Learn</p>
        </div>
      </div>

      <div class="card-items-box">
        <div class="grid-container">
          <h3 class="page-title">Explore the power of video storytelling</h3>
          <bv-card
            class="bv-card-item"
            v-for="item in cardList"
            :key="item.id"
            :icon="item.iconName"
            :description="item.describe"
            :title="item.title"
            @click="handleClick(item)"
          >
            <template #suffix v-if="item.isBeta">
              <div class="is-beta">Beta</div>
            </template>
          </bv-card>
        </div>
      </div>
      <div class="case-video">
        <p class="title">So many stunning videos have been generated</p>
        <UserCardList :list="caseInfoList" />
      </div>
    </div>
  </el-scrollbar>
</template>

<style lang="scss">
.case-video {
  .el-scrollbar__bar.is-horizontal > div {
    height: 0px;
  }
}
</style>

<style lang="scss" scoped>
.learn-btn-box {
  width: 100%;
  padding-top: 9px;
  margin-right: 20px;
  padding-right: 20px;
  height: 30px;
  .learn-btn {
    width: 84px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: rgba(31, 35, 41, 0.08);
    }
  }

  .tip-content {
    margin-left: 6px;
  }
}

.page-title {
  position: absolute;
  top: -82px;
  left: 20px;
  color: #000;
  font-family: Inter-variant;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  min-width: 523px;
}

.similar-page-title {
  position: absolute;
  top: -76px;
  left: 20px;
  color: #000;
  font-family: Inter-variant;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  min-width: 523px;
}

.workspace-page {
  display: flex;
  flex-direction: column;
}

.card-items-box {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: fit-content;
  padding-inline: 136px;
  flex-direction: column;
  max-width: 1200px;
  margin-top: 96px;
}

.case-video {
  margin-top: 70px;
  margin-bottom: 160px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  height: 650px;

  .el-scrollbar__thumb {
    width: 0px !important;
  }
}

.scroll-container {
  padding-left: 156px;
}

#scroll-element {
  cursor: pointer;
  &:active {
    cursor: grab;
  }
}

.case-video .title {
  color: #000;
  font-family: Inter-variant;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  margin-bottom: 48px;
  padding-left: 156px;
}

.case-video-item-container {
  display: inline-block;
  margin-right: 34.7px;
}

.main-content {
  padding-left: 0px !important;
}

.grid-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 8px;
}

.bv-card-item {
  position: relative;
  min-width: 350px;
  max-width: 500px;
  max-height: 154px;
  min-height: 124px;

  .is-beta {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 20px;
    border-radius: 2px;
    background: #ebedef;
    color: #060606;
    text-align: center;
    font-family: Inter-variant;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 10px;
  }
}

.similar-video-container {
  position: relative;
  padding-left: 20px;
}

.similar-video-card {
  width: 100%;
  height: 280px;
  border-radius: 10px;
  background: #6741ff;
  margin-bottom: 70px;
  padding: 15px 50px;
  display: flex;
  min-width: 750px;
  display: flex;
  align-items: center;
}

.transform-icon {
  position: absolute;
  z-index: 1;
  width: 108px;
  height: 58px;
  left: 30%;
  bottom: 58px;
}

.similar-video-card .image-container {
  display: flex;
  align-items: center;
  margin-right: 49px;
  position: relative;
}

.similar-video-card .text-container {
  width: 100%;
  max-width: 395px;

  p {
    color: #fff;
    font-family: Inter-variant;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
  }
  .button {
    width: 270px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    background: #00ff94;
    color: #060606;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: Inter-variant;
    margin-top: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
}

.similar-video-card .image-box {
  height: 100%;
  width: 160px;
  border-radius: 10px;
  margin-right: 20px;

  img {
    width: 100%;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .card-items-box {
    height: auto;
  }

  .card-items-box {
    padding: 0px 50px;
  }

  .case-video .title {
    padding-left: 70px;
  }

  .scroll-container {
    padding-left: 70px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 16px;
  }

  .bv-card-item {
    min-width: 350px;
    max-width: 468px;
    height: 124px;
  }
}

@media screen and (min-width: 1920px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 16px;
  }
  .card-items-box {
    min-width: 1650px;
  }
  .bv-card-item {
    min-width: 350px;
    max-width: 468px;
    height: 124px;
  }
}

@media screen and (min-width: 2400px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 16px;
  }
  .card-items-box {
    min-width: 2000px;
  }
  .bv-card-item {
    min-width: 350px;
    max-width: 468px;
    height: 124px;
  }
}

.carousel-container {
  display: flex;
}
</style>
