<template>
  <div class="newcard-item-container" @click="add">
    <div class="newcard-item-tip-container">
      <svgIcon name="icon_upload" :size="32" style="color: #646a73" />
      <span class="upload-tip">Create Avatar</span>
      <span class="learn-tip" @click.stop="$emit('preview')">Learn</span>
    </div>
    <div class="newcard-container">
    </div>
    <div class="newcard-footer"></div>
  </div>
  <SubscribeDialog
    :visible="subscribeModalVisible"
    @close="subscribeModalVisible = false"
    :showIntroduction="false"
  />
  <BvUpgradeModal 
    v-model="upgradeModalVisible"
    title="Upgrade to create your own avatar"
    :content="upgradeTipContent"
    @confirm="subscribeModalVisible = true"
  />
</template>

<script setup>
import { useMessage } from '@/utils';
import { addNewCharacter } from '@/api/character';
import { useSubscriptionInfo } from '@/store/modules/user';
import BvUpgradeModal from "@/components/common/bv-upgrade-modal/index.vue";
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

const emit = defineEmits("preview");

const { refresh } = useSubscriptionInfo();
const message = useMessage();
const router = useRouter();
const loading = ref(false);
const upgradeTipContent = ref("");
const upgradeModalVisible = ref(false);
const subscribeModalVisible = ref(false);

const add = async () => {
  if (loading.value) return;
  loading.value = true;
  const {data, success, code, msg} = await addNewCharacter();
  if (success) {
    refresh();
    router.push({
      path: "/avatar/edit",
      query: {
        characterId: data.characterId,
      }
    });
  }
  else if (code === 90013){
    upgradeTipContent.value = msg;
    upgradeModalVisible.value = true;
  }
  else {
    message.error("Character created Failed");
  }
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.newcard-item-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #f4f5f7;
  }
}

.newcard-container {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.newcard-footer {
  height: 76px;
}

.newcard-item-tip-container {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-tip {
  font-size: 14px;
  margin-top: 16px;
  color: #8F959E;
}

.learn-tip {
  font-size: 14px;
  color: #646A73;
  text-decoration: underline;
  cursor: pointer;
}
</style>