<template>
  <el-scrollbar class="scenelist-scrollbar">
    <div class="scenelist-container">
      <template
        v-if="similarVideoType === 'hook_start'"  
      >
        <div
          v-for="(scene, index) in hookScenes"
          class="scene-wrapper"
          :key="scene.sceneId"
        >
          <Hook 
            :scene="scene" 
          />
        </div>
      </template>
      <template
        v-if="similarVideoType === 'viral_hook'"  
      >
        <div
          v-for="(scene, index) in hookScenes"
          class="scene-wrapper"
          :key="scene.sceneId"
        >
          <ViralHook 
            :scene="scene" 
          />
        </div>
      </template>
      <template
        v-if="similarVideoType === 'voice_sticker'"  
      >
        <div
          v-for="(scene, index) in voiceStickerScenes"
          class="scene-wrapper"
          :key="scene.sceneId"
        >
          <VoiceSticker 
            :scene="scene" 
          />
        </div>
      </template>
      <template
        v-if="similarVideoType === 'b_roll'"  
      >
        <div
          v-for="(scene, index) in brollScenes"
          class="scene-wrapper"
          :key="scene.sceneId"
        >
          <Broll 
            :scene="scene"
          />
        </div>
      </template>
      <div 
        v-for="(scene, index) in defaultScenes"
        class="scene-wrapper default"
        :key="scene.sceneId"
      >
        <Scene 
          :scene="scene" 
          :index="index"
        />
      </div>
    </div>
    <Loading/>
  </el-scrollbar>
</template>

<script setup>
import Scene from "./scene.vue";
import Loading from "./loading.vue";
import Hook from "../hook/hook.vue";
import Broll from "../hook/broll.vue";
import ViralHook from "../hook/viralhook.vue";
import VoiceSticker from "../hook/voicesticker.vue";
import { useDraftStore } from "../../stores/draft";
import { useScriptStore } from "../../stores/script";

const {
  videoType
} = useDraftStore();

const {
  currentFrame,
  scenePlaying,
  loadingScene,
  activeScene,
  creator,
  scenes,
  pause,
  seekTo,
  setActiveScene,
  setScenePlaying,
} = useScriptStore();

const currentPlay = ref(null);
const defaultScenes = computed(() => {
  return scenes.value.filter((item) => 
    item.type === "default" || !item.type
  );
});
const hookScenes = computed(() => {
  return scenes.value.filter((item) =>  {
    const hookTypeList = ["hook_start", "viral_hook"];
    return hookTypeList.includes(item.type);
  });
});
const voiceStickerScenes = computed(() => {
  return scenes.value.filter((item) => 
    item.type === "voice_sticker"
  );
});
const brollScenes = computed(() => {
  return scenes.value.filter(item => 
    item.type === "b_roll"
  );
});
const similarVideoType = computed(() => {
  if (videoType.value.startsWith("similar_video")) {
    return scenes.value.find(
      item => item.type !== "default"
    )?.type || "boolvideoType"
  }
  else {
    return "boolvideoType";
  }
})

provide("currentPlay", currentPlay);

watch(
  creator,
  (newCreator, oldCreator) => {
    if (newCreator !== oldCreator && newCreator) {
      const hadGuided = localStorage.getItem("scene-editor-guide");
      if (hadGuided == 0) {
        setActiveScene(scenes.value.filter((item) => 
          !(["b_roll", "voice_sticker"].includes(item.type))
        )[0]);
      }
      else {
        setActiveScene(defaultScenes.value[0]);
      }
      seekTo(activeScene.value.start);
    }
  },
  {
    immediate: true,
  }
);

watch(
  currentFrame,
  async (value) => {
    const scene = currentPlay.value;
    if (scenePlaying.value) {
      if (scene?.end <= value) {
        await replayScene(scene);
        setScenePlaying(false);
      }
    }
  }
);

watch(
  loadingScene,
  (value) => {
    if (value) {
      pause();
    }
  }
);

const replayScene = async (scene) => {
  await pause();
  await seekTo(scene.start);
};

</script>

<style lang="scss" scoped>
.scenelist-container {
  min-height: 100vh;
  padding: 0px 14px 300px 0;
}

.scene-wrapper ~ .scene-wrapper {
  padding-bottom: 10px;
}

.scene-wrapper:not(:last-child) {
  padding-bottom: 10px;
}
</style>