<script setup>
import { useCreatorStore, useDragStore } from "../../stores";
import Widget from "./widget.vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { attrTabMap, activeNodeMap, nodeMap, creator } = useCreatorStore();
const dragData = useDragStore();

const ro = ref(null);
const cover = ref(null);
const hover = ref(false);
const dirtyId = ref(0);
const showRefLineLeft = ref(false);
const showRefLineCenter = ref(false);
const showRefLineRight = ref(false);
const showRefLineTop = ref(false);
const showRefLineMiddle = ref(false);
const showRefLineBottom = ref(false);
const style = computed(() => ({
  opacity: hover.value && dragData.target ? 0.5 : 1,
  backgroundColor: hover.value && dragData.target ? "#FFFFFF" : "transparent",
}));
const nodes = computed(() => Array.from(nodeMap.values()));
const activeNodes = computed(() =>
  nodes.value.filter(
    (node) =>
      node.isVisible &&
      node.isPrepared &&
      ["text", "subtitle", "image", "video", "sticker", "graphic"].includes(
        node.type
      )
  )
);

watch(
  nodes,
  (newNodes) => {
    for (const node of newNodes.values()) {
      if (["image", "video"].includes(node.type) && !attrTabMap[node.id]) {
        attrTabMap[node.id] = node.mask ? "mask" : "props";
      }
    }
  },
  { immediate: true }
);

onMounted(() => {
  ro.value = new ResizeObserver(resize);
  ro.value.observe(creator.value.view);
});
onBeforeUnmount(() => {
  if (ro.value) {
    ro.value.disconnect();
  }
  ro.value = null;
});

function resize() {
  if (creator.value && creator.value.view) {
    const element = cover.value;
    const view = creator.value.view;
    const width = view.clientWidth;
    const height = view.clientHeight;

    element.style.width = `${width}px`;
    element.style.height = `${height}px`;
    dirtyId.value++;
  }
}

function handleMouseUp() {
  if (dragData.target) {
    dragData.canvasMouseUp = true;
  }
}
provide("showRefLineLeft", showRefLineLeft);
provide("showRefLineCenter", showRefLineCenter);
provide("showRefLineRight", showRefLineRight);
provide("showRefLineTop", showRefLineTop);
provide("showRefLineMiddle", showRefLineMiddle);
provide("showRefLineBottom", showRefLineBottom);
</script>
<template>
  <div
    ref="cover"
    class="cover"
    :style="style"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @mouseup="handleMouseUp"
  >
    <template v-if="!disabled" v-for="(node, i) in activeNodes" :key="node.id">
      <Widget
        v-if="node.conf.mask"
        v-model:x="node.conf.mask.x"
        v-model:y="node.conf.mask.y"
        v-model:width="node.conf.mask.width"
        v-model:height="node.conf.mask.height"
        v-model:rotate="node.conf.mask.rotate"
        :nodes="activeNodes"
        :node="node"
        :index="i"
        :mask="node.mask"
        :active="activeNodeMap.has(node.id) && attrTabMap[node.id] === 'mask'"
        :dirty-id="dirtyId"
      />
      <Widget
        v-show="!node.mask || attrTabMap[node.id] !== 'mask'"
        v-model:x="node.conf.x"
        v-model:y="node.conf.y"
        v-model:width="node.conf.width"
        v-model:height="node.conf.height"
        v-model:scale="node.conf.scale"
        v-model:rotate="node.conf.rotate"
        :anchor="node.conf.anchor"
        :nodes="activeNodes"
        :node="node"
        :index="i"
        :active="
          activeNodeMap.has(node.id) &&
          (!node.mask || attrTabMap[node.id] !== 'mask')
        "
        :dirty-id="dirtyId"
      />
    </template>
    <div v-show="showRefLineLeft" class="ref-line ref-left"></div>
    <div v-show="showRefLineCenter" class="ref-line ref-center"></div>
    <div v-show="showRefLineRight" class="ref-line ref-right"></div>
    <div v-show="showRefLineTop" class="ref-line ref-top"></div>
    <div v-show="showRefLineMiddle" class="ref-line ref-middle"></div>
    <div v-show="showRefLineBottom" class="ref-line ref-bottom"></div>
  </div>
</template>

<style scoped>
.cover {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ref-line {
  position: absolute;
  background-color: #00d7b0;
  z-index: 7;
}
.ref-left,
.ref-center,
.ref-right {
  top: 0;
  width: 1px;
  height: 100%;
}
.ref-top,
.ref-middle,
.ref-bottom {
  left: 0;
  width: 100%;
  height: 1px;
}
.ref-left {
  left: 0;
}
.ref-center {
  left: 50%;
  transform: translateX(-50%);
}
.ref-right {
  right: 0;
}
.ref-top {
  top: 0;
}
.ref-middle {
  top: 50%;
  transform: translateY(-50%);
}
.ref-bottom {
  bottom: 0;
}
</style>
