<template>
  <!-- Card container -->
  <div class="card-box-scrollbar-wrapper">
    <el-scrollbar :always="true" class="card-box-scrollbar">
      <div class="card-box">
        <div 
          v-for="card in displayCardList"
          :key="card.name"
          class="card-container" 
          :class="{'active-border': currentHightLightCard == card.name}" 
        >
          <PricingCardItem
            v-if="card.children.length <= 1"
            :active="currentHightLightCard == card.name" 
            :card="card"
            :subscriptionState="subscriptionState"
            :isLoading="isLoading"
            :userOperationMap="userOperationMap"
          />
          <PricingCardScrollItem
            v-else
            :active="currentHightLightCard == card.name" 
            :card="card"
            :subscriptionState="subscriptionState"
            :isLoading="isLoading"
            :userOperationMap="userOperationMap"
          />
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import PricingCardItem from "./pricing-card-item.vue";
import PricingCardScrollItem from "./pricing-card-scroll-item.vue";
import { useSubscriptionInfo } from '@/store/modules/user';

const { subscriptionState } = storeToRefs(useSubscriptionInfo());

const props = defineProps({
  cardList: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  userOperationMap: {
    type: Object,
    required: true,
  },
});

const currentPlan =  ref('')
const currentPackageCode = ref(-1)

const subscribeList = [
  {
    packageCodes: [400101],
    highLightPackageCode: 400201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCodes: [400401],
    highLightPackageCode: 400201,
    highLightCardName: "Standard",
    cycleType: "month"
  },
  {
    packageCodes: [400201, 400202, 400203, 400204, 400205],
    highLightPackageCode: 400301,
    highLightCardName: "Pro",
    cycleType: "month"
  },
]

const currentHightLightCard = computed(() => {
  return subscribeList.find(item => item.packageCodes.includes(currentPackageCode.value))?.highLightCardName
})

const displayCardList = computed(() => {
  const { currentPlanName, packageCode } = subscriptionState.value;
  currentPlan.value = currentPlanName
  currentPackageCode.value = packageCode

  const list = props.cardList;
  return list;
});
</script>

<style lang="scss" scoped>
.card-box-scrollbar-wrapper {
  width: 100%;
  padding-bottom: 8px;
}

.card-box-scrollbar {
  width: 100%;
}

.card-box {
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(4, 342px);
  justify-items: center;
  gap: 1.5rem;
  margin-top: 36px;
  margin-bottom: 104px;
  padding: 0 1.5rem;
}

.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 36px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dee0e3;
  border-radius: 8px;
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.08);
}

.active-border {
  border: 2px solid #6741FF;
}
</style>
