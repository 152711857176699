<script setup lang="ts">
import { watch, onMounted, onBeforeUnmount } from 'vue';
import breadcrumb from './breadcrumb.vue';
import logo from '@/assets/icons/common/icon_title_logo.svg';
import similarVideo_logo from '@/assets/similar-video/similarVideo_logo.svg';
import avatar from '../avatar.vue';
import { PrimaryButton } from '@/components/common';
import EditExportInfoDialog from '@/components/common/exportDialog.vue';
import SubscribeDialog from './subscribeDialog/index.vue';
import UpgradeTip from './upgradeTip.vue';

import { setItem, getItem, removeItem } from '@/utils/storage';
import { useEditVideo } from '@/store/modules/editVideo';
import { useRouter, useRoute } from 'vue-router';
import { isLogin } from '@/utils/auth';
import { downloadFile, getFileExtension } from '@/utils';
import { useSubscriptionInfo } from '@/store/modules/user';
import { useTrackStore } from '@/store/modules/track';

const globalDomain = inject('globalDomain'); // 1-boolVide 2-similarVideo

const subscriptionInfo = useSubscriptionInfo();
const showExportBtn = ref(false);
const showDownloadBtn = ref(false);
const router = useRouter();
const route = useRoute();
const editVideoStore = useEditVideo();
const showExportDialog = ref(false);
const loading = ref(false);

const planName = ref('');
const showIntroduction = ref(false);
const starterCodes = [300401];
const subscribeModalVisible = ref(false);
const showUpgradeTip = ref(false);

const minWidth = ref(600);

const { collectData, track } = useTrackStore();
const { reqId, draftId, saveAssetInfo } = storeToRefs(editVideoStore);

const handleEditExport = () => {
  showExportDialog.value = true;
};

const showUpgradeBtn = computed(() => {
  return !showExportBtn.value && route.path != '/premium';
});

const handleEditDownload = async () => {
  loading.value = true;
  const { downloadUrl, rid, reqId, draftId } = route.query;
  const name = route.query.name as string;
  if (!downloadUrl) {
    return;
  }

  await downloadFile(
    downloadUrl as string,
    `${name}.${getFileExtension(downloadUrl)}`
  );
  collectData('boolvideo_download', {
    rid,
    reqId,
    draftId,
  });
  track('boolvideo_download');
  loading.value = false;
};

const showButtonList = [
  '/product-to-video/editVideo',
  '/template-to-video/editVideo',
  '/space/editVideo',
];

watch(
  () => router.currentRoute.value.path,
  (value) => {
    if (value) {
      const isInclude = showButtonList.includes(value);
      showExportBtn.value = isInclude ? true : false;
      showDownloadBtn.value = value == '/space/previewer' ? true : false;

      if (value != '/workspace') {
        // 非首页，不展示learn more
        showUpgradeTip.value = false;
        let count = getItem('showUpgradeTipCount')
          ? getItem('showUpgradeTipCount')
          : 0;
        setItem('showUpgradeTipCount', ++count);
      }
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

watchEffect(() => {
  switch (subscriptionInfo.$state.packageCode) {
    case 400201: 
    case 400202: 
    case 400203: 
    case 400204: 
    case 400205: 
      planName.value = "Standard";
      break;
    case 400301:
    case 400302:
    case 400303:
    case 400304:
    case 400305:
    case 400306:
      planName.value = 'Pro';
      break;
    case 300201:
      // Month
      planName.value = 'Standard';
      break;
    case 300202:
      // year
      planName.value = 'Annual standard';
      break;
    case 300301:
      // month
      planName.value = 'Pro';
      break;
    case 300302:
      planName.value = 'Annual pro';
      break;
    case 300401:
    case 300402:
    case 300404:
    case 400401:
      planName.value = 'Starter';
      break;
    case 300205:
      planName.value = 'RH Standard';
      break;
    case 300304:
      planName.value = 'LTD Pro';
      break;
    case 300305:
      planName.value = 'RH Pro';
      break;
    case 300204:
      planName.value = 'LTD Standard';
      break;
    case 300405:
      planName.value = 'RH Starter';
      break;
    default:
      planName.value = 'Free Plan';
      break;
  }
});

const handleExportSuccess = () => {
  showExportDialog.value = false;
};

const handleUpgrade = () => {
  if (showUpgradeTip.value) {
    // starter learn more互斥不可点击
    return;
  }

  subscribeModalVisible.value = true;
  showIntroduction.value = false;
};

const handleCloseSubscribeModal = () => {
  subscribeModalVisible.value = false;
  showIntroduction.value = false;
};

const checkShowUpgradeTipCount = () => {
  let count = getItem('showUpgradeTipCount')
    ? getItem('showUpgradeTipCount')
    : 0;
  if (starterCodes.includes(subscriptionInfo.$state.packageCode) && count < 2) {
    showUpgradeTip.value = true;
    setItem('showUpgradeTipCount', ++count);
  } else {
    showUpgradeTip.value = false;
  }
};

watch(
  () => getItem('showUpgradeTipCount'),
  () => {
    checkShowUpgradeTipCount();
  },
  {
    deep: true,
    immediate: true,
  }
);

const getBreadcrumbWith = () => {
  const windowWidth = window.innerWidth;
  minWidth.value = Math.ceil(windowWidth);
};

onMounted(() => {
  checkShowUpgradeTipCount();
  getBreadcrumbWith();

   window.addEventListener('resize', getBreadcrumbWith);
  // window.onresize = () => {
  //    console.log('2222')
  //   getBreadcrumbWith();
  // };
});

onBeforeUnmount(() => {
  window.onresize = null; // 移除事件监听
});

const handleLearnMore = () => {
  showUpgradeTip.value = false;
  showIntroduction.value = true;
  subscribeModalVisible.value = true;
};
</script>

<template>
  <div
    class="header-container h-header flex justify-between pl-1.5 pr-3.5 relative"
    id="workspace-header"
  >
    <div class="flex items-center">
      <router-link to="/workspace">
        <img
          :src="logo"
          class="mr-10 cursor-pointer"
          v-if="globalDomain == 1"
        />
        <img
          :src="similarVideo_logo"
          class="mr-10 cursor-pointer"
          v-if="globalDomain == 2"
        />
      </router-link>
      <breadcrumb :minWidth="minWidth" />
    </div>

    <div
      class="flex items-center"
      v-if="router.currentRoute.value.path != '/template-gallery'"
    >
      <PrimaryButton
        padding="10px 36px"
        class="mr-7"
        v-if="showExportBtn"
        @click="handleEditExport"
      >
        <p class="text-sm leading-22" style="width: 66px">Export</p>
      </PrimaryButton>

      <PrimaryButton
        padding="10px 36px"
        class="mr-7"
        v-if="showDownloadBtn"
        @click="handleEditDownload"
      >
        <p class="text-sm leading-22" style="width: 66px">Download</p>
        <template #preIcon v-if="loading">
          <svg-icon
            name="icon_loading"
            color="#fff"
            :size="16"
            class="animate-spin mr-2"
          />
        </template>
      </PrimaryButton>

      <div class="relative mr-6" v-if="showUpgradeBtn">
        <div
          class="subscribe-status-btn"
          @click="handleUpgrade"
          v-if="subscriptionInfo.$state.packageCode == 300101"
        >
          Upgrade
        </div>
        <div
          class="subscribe-status-btn flex items-center"
          @click="handleUpgrade"
          v-else
        >
          <svg-icon name="icon_diamond" :size="16" class="mr-1"></svg-icon
          >{{ planName }}
        </div>
        <UpgradeTip
          rootStyle="position: absolute; top: 50px; right: 0px "
          @confirm="handleLearnMore"
          v-if="showUpgradeTip"
        />
      </div>

      <avatar
        v-if="isLogin"
        :packageCode="subscriptionInfo.$state.packageCode"
      />
      <div v-else style="display: flex">
        <common-button
          class="login-button"
          type="text"
          plain
          size="extra-small"
          @click="router.push({ name: 'Login' })"
          >Log in</common-button
        >
        <common-button
          class="signup-button"
          plain
          size="extra-small"
          @click="router.push({ name: 'Signup' })"
          >Sign up</common-button
        >
      </div>
    </div>

    <edit-export-info-dialog
      exportType="edit"
      :reqId="reqId"
      :editAssets="saveAssetInfo"
      :draftId="draftId"
      :visible="showExportDialog"
      @confirm="handleExportSuccess"
      @close="() => (showExportDialog = false)"
    />
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="handleCloseSubscribeModal"
      :showIntroduction="showIntroduction"
    />
  </div>
</template>

<style lang="scss" scoped>
.items-center {
  align-items: center;
}

.login-button > :deep(button.el-button) {
  color: #060606;
}

.signup-button > :deep(button.el-button) {
  color: #060606;
  border-color: #bbbfc4;
}

.subscribe-status-btn {
  display: inline-flex;
  padding: 8px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background: #000;
  cursor: pointer;

  color: #ffeca9;
  text-align: center;
  font-family: 'Helvetica Neue';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
