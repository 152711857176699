<script setup>
import {
  blendOptions,
  tagOptions,
  maskOptions,
  toolOptions,
} from '@/constants/image';
import { useCreatorStore, useDraftStore, useHistoryStore } from '../../stores';
import { useTrackStore } from '@/store/modules/track';
import Attr from './attr.vue';
import Keyframe from './keyframe.vue';
import Extra from './extra.vue';
import ImageBgRemoveDialog from '@/pages/aiTools/views/bgRemove/imageBgRemoveDialog.vue';
import ObjectEraserDialog from '@/pages/aiTools/views/objectEraser/modal.vue';
import ImageEnhancerDialog from '@/pages/aiTools/views/enhancer/imageEnhancerDialog.vue';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import { useMessage } from '@/utils';
import { authenticator } from '@/utils/authenticate';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useAssetModal } from '../../utils/instance';

const emit = defineEmits([
  'update:src',
  'update:hdUrl',
  'update:materialMeta',
  'update:x',
  'update:y',
  'update:scale',
  'update:rotate',
  'update:opacity',
  'update:keyframes',
  'update:blend',
  'update:mask',
  'update:tag',
]);
const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  initialState: {
    type: Object,
    default: {},
  },
  hdUrl: {
    type: String,
    default: null,
  },
  materialMeta: {
    type: Object,
    default: undefined,
  },
  src: {
    type: String,
    default: null,
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  scale: {
    type: Number,
    default: 1,
  },
  opacity: {
    type: Number,
    default: 1,
  },
  keyframes: {
    type: Object,
    default: undefined,
  },
  blend: {
    type: String,
    default: 'normal',
  },
  mask: {
    type: Object,
    default: undefined,
  },
  tag: {
    type: String,
    default: null,
  },
});

const bgTrackHelper = (ei) => {
  collectData('boolvideo_timeline_edit_click', {
    element_type: props.node.type,
    element_id: ei,
    click: 'add_background',
  });
  track('boolvideo_timeline_edit_click');
  clearEventData('boolvideo_timeline_edit_click');
};

const backgroundOptions = [
  {
    title: 'None',
    name: 'editor_none',
    size: 18,
    style: {
      background: '#F3F5F7',
    },
    onClick: (title) => {
      if (title === activeBackground.value) return;
      bgTrackHelper('none');
      removeBackground(props.node.parent);
      submit();
    },
  },
  {
    title: 'Upload',
    name: 'editor_upload',
    size: 20,
    style: {
      borderColor: '#E5E7EB',
    },
    onClick: () => {
      assetModal.open(
        route.query.draftId,
        ({ url }) => {
          setBackgroundImage(props.node.parent, url);
          submit();
          bgTrackHelper('upload_media');
        },
        { mode: 'background', sourceType: 'image' }
      );
    },
  },
  {
    title: 'Color fill',
    style: {},
  },
  {
    title: 'Blur1',
    style: {},
    blur: 1,
    onClick: (title) => {
      if (title === activeBackground.value) return;
      bgTrackHelper(title);
      setBackgroundBlur(props.node.parent, 0.02);
      submit();
    },
  },
  {
    title: 'Blur2',
    style: {},
    blur: 2,
    onClick: (title) => {
      if (title === activeBackground.value) return;
      bgTrackHelper(title);
      setBackgroundBlur(props.node.parent, 0.05);
      submit();
    },
  },
  {
    title: 'Blur3',
    style: {},
    blur: 3,
    onClick: (title) => {
      if (title === activeBackground.value) return;
      bgTrackHelper(title);
      setBackgroundBlur(props.node.parent, 0.1);
      submit();
    },
  },
];

const { showMaterialDialog, replaceData, attrTabMap, updateProject, updateCloud } = useCreatorStore();
const { collectData, track, clearEventData } = useTrackStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const assetModal = useAssetModal();
const route = useRoute();
const message = useMessage();
const modalManager = useModalManager();
const showTool = ref(false);
const currentTool = ref(null);
const step = ref(1);
const subscribeModalVisible = ref(false);
const globalDomain = inject("globalDomain");

const x = ref(props.x);
const y = ref(props.y);
const rotate = ref(props.rotate);
const scale = ref(props.scale);
const opacity = ref(props.opacity);

const maskX = ref(0);
const maskY = ref(0);
const maskW = ref(0);
const maskH = ref(0);
const maskR = ref(0);
const radius = ref(0);

const dirty = reactive({
  x: 0,
  y: 0,
  rotate: 0,
  scale: 0,
  opacity: 0,
});
const dirtyMask = reactive({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  rotate: 0,
});

const activeBackground = computed(() => {
  const scene = props.node.parent;
  const conf = scene.conf;
  const { backgroundImage, backgroundColor, backgroundBlur } = conf;

  if (backgroundImage) {
    return 'Upload';
  } else if (backgroundColor) {
    return 'Color fill';
  } else if (backgroundBlur === 0.02) {
    return 'Blur1';
  } else if (backgroundBlur === 0.05) {
    return 'Blur2';
  } else if (backgroundBlur === 0.1) {
    return 'Blur3';
  } else {
    return 'None';
  }
});

onBeforeMount(() => {
  const { mask, node } = props;

  if (mask) {
    maskX.value = mask.x;
    maskY.value = mask.y;
    maskW.value = mask.width;
    maskH.value = mask.height;
    maskR.value = mask.rotate;
    radius.value = mask.radius;
  }
  if (localStorage.getItem('image_attr_guide') !== '0') {
    attrTabMap[node.id] = 'props';
  }
});

watch(
  () => props.node.updateId,
  () => {
    const node = props.node;
    const { mask } = node;
    const [nx, ny] = node.getXY();
    const ns = node.getScale();
    const nr = node.getRotation();
    const no = node.getOpacity();

    x.value = nx;
    y.value = ny;
    scale.value = ns;
    rotate.value = nr;
    opacity.value = no;

    if (mask) {
      const [mx, my] = mask.getXY();
      const [mw, mh] = mask.getWH();
      const mr = mask.getRotation();

      maskX.value = mx;
      maskY.value = my;
      maskW.value = mw;
      maskH.value = mh;
      maskR.value = mr;
    }
  },
  { immediate: true }
);
watch(
  () => props.x,
  (newX) => {
    updateX(newX, false, true);
  }
);
watch(
  () => props.y,
  (newY) => {
    updateY(newY, false, true);
  }
);
watch(
  () => props.scale,
  (newScale) => {
    updateScale(newScale, false, true);
  }
);
watch(
  () => props.rotate,
  (newRotation) => {
    updateRotate(newRotation, false, true);
  }
);
watch(
  () => props.opacity,
  (newOpacity) => {
    updateOpacity(newOpacity, false, true);
  }
);
watch(
  () => props.mask?.x,
  (newX) => {
    updateMaskX(newX, false, true);
  }
);
watch(
  () => props.mask?.y,
  (newY) => {
    updateMaskY(newY, false, true);
  }
);
watch(
  () => props.mask?.width,
  (newWidth) => {
    updateMaskWidth(newWidth, false, true);
  }
);
watch(
  () => props.mask?.height,
  (newHeight) => {
    updateMaskHeight(newHeight, false, true);
  }
);
watch(
  () => props.mask?.rotate,
  (newRotation) => {
    updateMaskRotation(newRotation, false, true);
  }
);

function submit() {
  commit();
  updateDraft();
}

function removeBackground(scene) {
  const conf = scene.conf;

  if (conf.backgroundImage) {
    delete conf.backgroundImage;
  }
  if (conf.backgroundColor) {
    delete conf.backgroundColor;
  }
  if (conf.backgroundOpacity) {
    delete conf.backgroundOpacity;
  }
  if (conf.backgroundBlur) {
    delete conf.backgroundBlur;
  }
}

function setBackgroundImage(scene, url) {
  removeBackground(scene);
  scene.conf.backgroundImage = url;
  scene.conf.backgroundOpacity = 0.3;
}

function setBackgroundColor(scene, color, shouldSubmit) {
  removeBackground(scene);
  scene.conf.backgroundColor = color;
  shouldSubmit && submit();
  shouldSubmit && bgTrackHelper('Color fill');
}

function setBackgroundBlur(scene, blur) {
  removeBackground(scene);
  scene.conf.backgroundBlur = blur;
}

async function ApplyAllBackground() {
  const trackHelper = () => {
    let ei = 'none';
    const { backgroundImage, backgroundColor, backgroundBlur } = scene.conf;
    if (backgroundImage) {
      ei = 'upload_media';
    } else if (backgroundColor) {
      ei = 'Color fill';
    } else if (backgroundBlur) {
      switch (backgroundBlur) {
        case 0.1:
          ei = 'Blur3';
          break;
        case 0.05:
          ei = 'Blur2';
          break;
        case 0.02:
          ei = 'Blur1';
          break;
      }
    }
    collectData('boolvideo_timeline_edit_click', {
      element_id: ei,
      click: 'apply_all_background',
    });
    track('boolvideo_timeline_edit_click');
    clearEventData('boolvideo_timeline_edit_click');
  };

  const scene = props.node.parent;
  const { backgroundImage, backgroundColor, backgroundBlur } = scene.conf;
  const scenes = scene.parent.children.filter((s) => s !== scene);

  if (backgroundImage) {
    for (const scene of scenes) {
      setBackgroundImage(scene, backgroundImage);
    }
  } else if (backgroundColor) {
    for (const scene of scenes) {
      setBackgroundColor(scene, backgroundColor);
    }
  } else if (backgroundBlur) {
    for (const scene of scenes) {
      setBackgroundBlur(scene, backgroundBlur);
    }
  } else {
    for (const scene of scenes) {
      removeBackground(scene);
    }
  }
  submit();
  message.success('All backgrounds successfully applied');
  trackHelper();
}

function resetLayoutProperty() {
  const { initialState } = props;
  emit('update:x', initialState.x);
  emit('update:y', initialState.y);
  emit('update:rotate', initialState.rotation);
  emit('update:scale', initialState.scale);
  emit('update:opacity', initialState.opacity);
  emit('update:keyframes', initialState.keyframes);
  submit();
}

function resetBlendProperty() {
  const { initialState } = props;
  emit('update:blend', initialState.blend);
  submit();
}

function resetMaskLayoutProperty() {
  const node = props.node;
  const { x, y, width, height, rotation, keyframes } = node.mask.initialState;
  emit('update:mask', {
    ...props.mask,
    x,
    y,
    width,
    height,
    rotate: rotation,
    keyframes,
  });
  submit();
}

function resetExtraProperty() {
  const { initialState } = props;
  emit('update:tag', initialState.tag);
  submit();
}

function updateSrc(url, hdUrl, width480, width1080, shouldUpdate = true) {
  const materialMeta = { width480, width1080, url1080: hdUrl };

  emit('update:src', url);
  emit('update:hdUrl', hdUrl);
  emit('update:materialMeta', materialMeta);

  if (shouldUpdate) {
    updateProject.value++;
    updateCloud.value++;
  }
  submit();
}

function updateX(value, shouldSubmit, noEmit) {
  x.value = value;
  dirty.x++;
  !noEmit && emit('update:x', value);
  shouldSubmit && submit();
}

function updateY(value, shouldSubmit, noEmit) {
  y.value = value;
  dirty.y++;
  !noEmit && emit('update:y', value);
  shouldSubmit && submit();
}

function updateRotate(value, shouldSubmit, noEmit) {
  rotate.value = value;
  dirty.rotate++;
  !noEmit && emit('update:rotate', value);
  shouldSubmit && submit();
}

function updateScale(value, shouldSubmit, noEmit) {
  scale.value = value;
  dirty.scale++;
  !noEmit && emit('update:scale', value);
  shouldSubmit && submit();
}

function updateOpacity(value, shouldSubmit, noEmit) {
  opacity.value = value;
  dirty.opacity++;
  !noEmit && emit('update:opacity', value);
  shouldSubmit && submit();
}

function updateKeyframes(value) {
  emit('update:keyframes', value);
}

function updateBlend(value) {
  emit('update:blend', value);
  submit();
}

function updateMaskX(value, shouldSubmit, noEmit) {
  maskX.value = value;
  dirtyMask.x++;
  !noEmit && emit('update:mask', { ...props.mask, x: value });
  shouldSubmit && submit();
}

function updateMaskY(value, shouldSubmit, noEmit) {
  maskY.value = value;
  dirtyMask.y++;
  !noEmit && emit('update:mask', { ...props.mask, y: value });
  shouldSubmit && submit();
}

function updateMaskWidth(value, shouldSubmit, noEmit) {
  maskW.value = value;
  dirtyMask.width++;
  !noEmit && emit('update:mask', { ...props.mask, width: value });
  shouldSubmit && submit();
}

function updateMaskHeight(value, shouldSubmit, noEmit) {
  maskH.value = value;
  dirtyMask.height++;
  !noEmit && emit('update:mask', { ...props.mask, height: value });
  shouldSubmit && submit();
}

function updateMaskRotation(value, shouldSubmit, noEmit) {
  maskR.value = value;
  dirtyMask.rotate++;
  !noEmit && emit('update:mask', { ...props.mask, rotate: value });
  shouldSubmit && submit();
}

function updateMaskRadius(value, shouldSubmit) {
  radius.value = value;
  emit('update:mask', { ...props.mask, radius: value });
  shouldSubmit && submit();
}

function updateMaskK(value) {
  emit('update:mask', { ...props.mask, keyframes: value });
}

function updateTag(value) {
  emit('update:tag', value);
  submit();
}

function clickMask(option) {
  const { node, mask } = props;

  if (option.shape === mask?.shape) {
    return;
  }
  if (!option.shape) {
    emit('update:mask', null);
  } else {
    let [x, y] = node.getXY();
    const [width, height] = node.getWH();
    radius.value = 0;

    if (typeof mask?.x === 'number') {
      x = mask.x;
    }
    if (typeof mask?.y === 'number') {
      y = mask.y;
    }

    emit('update:mask', {
      mode: 'graph',
      shape: option.shape,
      x,
      y,
      width: width / 2,
      height: option.shape === 'rect' ? height / 2 : width / 2,
      radius: 0,
    });
  }
  submit();
}

async function clickTool(tool) {
  if (!(await authenticator.authenticate('boolvideo_tools_image'))) {
    modalManager.applyTemplate('noCredits', {
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  currentTool.value = tool;
  nextTick(() => (showTool.value = true));
}

function handleReplace() {
  replaceData.node = props.node;
  showMaterialDialog.value = true;
}
</script>
<template>
  <Attr>
    <el-tabs v-model="attrTabMap[node.id]">
      <el-tab-pane label="Properties" name="props">
        <el-scrollbar>
          <div class="attr-content">
            <bv-guide
              name="image_attr_guide"
              title="Media replace"
              description="Directly replace material in a track."
              footer-text="1/2"
              confirm-text="Next"
              placement="left-start"
              :disappear="false"
              :visible="step === 1"
              @click="step = 2"
            >
              <template #reference>
                <div class="form">
                  <button class="plain" @click="handleReplace">
                    Media replace
                  </button>
                </div>
              </template>
            </bv-guide>
            <div class="form keyframe">
              <div class="form-header">
                <span>Layout</span>
                <div class="right">
                  <icon-button
                    name="editor_reset"
                    tip="Reset"
                    :size="14"
                    @click="resetLayoutProperty"
                  />
                  <bv-guide
                    name="image_attr_guide"
                    title="Keyframe edit"
                    description="Add keyframes to materials to achieve animation effects."
                    footer-text="2/2"
                    placement="bottom-end"
                    :description-style="{ marginBottom: '8px' }"
                    :visible="step === 2"
                  >
                    <template #reference>
                      <Keyframe
                        :should-add="false"
                        :properties="{ x, y, rotate, scale, opacity }"
                        :keyframes="keyframes"
                        :dirty="dirty"
                        @change="updateKeyframes"
                      />
                    </template>
                    <template #extra>
                      <a
                        href="https://www.notion.so/boolv/6-Keyframe-86702bb3d4d64ce8a13dd5445d05f71a"
                        target="_blank"
                        >Learn more</a
                      >
                    </template>
                  </bv-guide>
                </div>
              </div>
              <div class="form-content">
                <div class="col col-input">
                  <input-number
                    rounded
                    :model-value="x"
                    @input="updateX"
                    @change="updateX($event, true)"
                  >
                    <template #prefix>X</template>
                  </input-number>
                  <input-number
                    rounded
                    :model-value="y"
                    @input="updateY"
                    @change="updateY($event, true)"
                  >
                    <template #prefix>Y</template>
                  </input-number>
                  <Keyframe
                    :properties="{ x, y }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="rotation">
                    <input-number
                      angle
                      :model-value="rotate"
                      :step="Math.PI / 180"
                      @input="updateRotate"
                      @change="updateRotate($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_angle" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ rotate }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Scale">
                    <input-number
                      percent
                      :model-value="scale"
                      :min="0.01"
                      :max="10"
                      :step="0.01"
                      @input="updateScale"
                      @change="updateScale($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_scale" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ scale }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Opacity">
                    <input-number
                      percent
                      :model-value="opacity"
                      :min="0"
                      :max="1"
                      :step="0.01"
                      @input="updateOpacity"
                      @change="updateOpacity($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_opacity" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ opacity }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
              </div>
            </div>
            <div class="form has-keyframe">
              <div class="form-header">
                <span>Blend</span>
                <icon-button
                  name="editor_reset"
                  tip="Reset"
                  :size="14"
                  @click="resetBlendProperty"
                />
              </div>
              <div class="form-content">
                <div class="col col-input col-small">
                  <bv-select
                    width="226px"
                    style="margin-right: 0"
                    :model-value="blend"
                    :options="blendOptions"
                    @change="updateBlend"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="node.parent.type === 'scene'"
              class="form"
              style="padding: 24px 16px"
            >
              <div class="form-header">
                <span>Background</span>
                <span class="text-button" @click="ApplyAllBackground"
                  >Apply all</span
                >
              </div>
              <div class="form-content">
                <div class="grid-list">
                  <div
                    v-for="(option, i) in backgroundOptions"
                    class="grid-item"
                    :key="i"
                  >
                    <div
                      class="image-wrapper"
                      :style="option.style"
                      :class="{ active: option.title === activeBackground }"
                      @click="option.onClick(option.title)"
                    >
                      <div
                        class="background-image"
                        v-if="
                          option.title === 'Upload' &&
                          node.parent.conf.backgroundImage
                        "
                      >
                        <img
                          loading="lazy"
                          draggable="false"
                          :src="node.parent.conf.backgroundImage"
                        />
                        <div class="image-mask"></div>
                        <svg-icon
                          :name="option.name"
                          :size="option.size"
                          color="#FFFFFF"
                        />
                      </div>
                      <color-picker
                        v-else-if="option.title === 'Color fill'"
                        :value="node.parent.conf.backgroundColor || '#D9DFFF'"
                        :size="68"
                        @input="setBackgroundColor(node.parent, $event)"
                        @change="setBackgroundColor(node.parent, $event, true)"
                      />
                      <img
                        v-else-if="option.blur"
                        loading="lazy"
                        draggable="false"
                        :style="`filter: blur(${option.blur}px)`"
                        :src="src"
                      />
                      <svg-icon
                        v-else-if="option.name"
                        :name="option.name"
                        :size="option.size"
                      />
                    </div>
                    <div class="title">{{ option.title }}</div>
                  </div>
                </div>
              </div>
            </div>
            <extra resetable :config="node.conf" @reset="resetExtraProperty">
              <div class="col col-input col-small">
                <bv-select
                  clearable
                  placeholder="Select tag"
                  width="226px"
                  style="margin-right: 0"
                  :model-value="tag"
                  :options="tagOptions"
                  @change="updateTag"
                />
              </div>
            </extra>
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="Mask" name="mask">
        <el-scrollbar>
          <div class="attr-content">
            <div class="form">
              <button class="plain" @click="handleReplace">
                Media replace
              </button>
            </div>
            <div class="form" :style="{ padding: '24px 16px' }">
              <div class="grid-list">
                <div
                  v-for="(option, i) in maskOptions"
                  class="grid-item"
                  :key="i"
                >
                  <div
                    class="image-wrapper"
                    :class="{ active: option.shape === mask?.shape }"
                    :style="option.style"
                    @click="clickMask(option)"
                  >
                    <svg-icon
                      :name="option.name"
                      :color="option.color"
                      :size="option.size"
                    />
                  </div>
                  <div class="title">{{ option.title }}</div>
                </div>
              </div>
              <div
                v-if="mask?.shape === 'rect'"
                class="col col-input col-single"
                :style="{ marginTop: '22px' }"
              >
                <bv-tip content="Radius">
                  <input-number
                    :model-value="radius"
                    :min="0"
                    @input="updateMaskRadius"
                    @change="updateMaskRadius($event, true)"
                  >
                    <template #prefix>
                      <svg-icon name="editor_radius" :size="18" />
                    </template>
                  </input-number>
                </bv-tip>
              </div>
            </div>
            <div class="form keyframe" v-if="mask">
              <div class="form-header">
                <span>Layout</span>
                <div class="right">
                  <icon-button
                    name="editor_reset"
                    tip="Reset"
                    :size="14"
                    @click="resetMaskLayoutProperty"
                  />
                  <Keyframe
                    :should-add="false"
                    :properties="{
                      x: maskX,
                      y: maskY,
                      width: maskW,
                      height: maskH,
                      rotate: maskR,
                    }"
                    :keyframes="mask.keyframes"
                    :dirty="dirtyMask"
                    @change="updateMaskK"
                  />
                </div>
              </div>
              <div class="form-content">
                <div class="col col-input">
                  <input-number
                    rounded
                    :model-value="maskX"
                    @input="updateMaskX"
                    @change="updateMaskX($event, true)"
                  >
                    <template #prefix>X</template>
                  </input-number>
                  <input-number
                    rounded
                    :model-value="maskY"
                    @input="updateMaskY"
                    @change="updateMaskY($event, true)"
                  >
                    <template #prefix>Y</template>
                  </input-number>
                  <Keyframe
                    :properties="{ x: maskX, y: maskY }"
                    :keyframes="mask.keyframes"
                    :dirty="dirtyMask"
                    @change="updateMaskK"
                  />
                </div>
                <div class="col col-input">
                  <input-number
                    rounded
                    :min="1"
                    :model-value="maskW"
                    @input="updateMaskWidth"
                    @change="updateMaskWidth($event, true)"
                  >
                    <template #prefix>W</template>
                  </input-number>
                  <input-number
                    rounded
                    :min="1"
                    :model-value="maskH"
                    @input="updateMaskHeight"
                    @change="updateMaskHeight($event, true)"
                  >
                    <template #prefix>H</template>
                  </input-number>
                  <Keyframe
                    :properties="{ width: maskW, height: maskH }"
                    :keyframes="mask.keyframes"
                    :dirty="dirtyMask"
                    @change="updateMaskK"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="rotation">
                    <input-number
                      angle
                      :model-value="maskR"
                      :step="Math.PI / 180"
                      @input="updateMaskRotation"
                      @change="updateMaskRotation($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_angle" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ rotate: maskR }"
                    :keyframes="mask.keyframes"
                    :dirty="dirtyMask"
                    @change="updateMaskK"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane v-if="hdUrl && globalDomain === 1" name="aiTools">
        <template #label>
          <svg-icon
            name="editor_ai_tools"
            :size="16"
            :style="{ marginRight: '8px' }"
          />
          <span>Tools</span>
        </template>
        <el-scrollbar>
          <div class="attr-content">
            <div class="tool-container">
              <div
                class="tool-item"
                v-for="(option, i) in toolOptions"
                :key="i"
                @click="clickTool(option)"
              >
                <span>{{ option.title }}</span>
                <svg-icon name="editor_arrow_forward" :size="18" />
              </div>
            </div>
          </div>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <image-bg-remove-dialog
      v-if="currentTool?.id === 0"
      v-model="showTool"
      :src="hdUrl"
      @change="updateSrc"
    />
    <object-eraser-dialog
      v-else-if="currentTool?.id === 1"
      v-model="showTool"
      :src="hdUrl"
      @change="updateSrc"
    />
    <image-enhancer-dialog
      v-else-if="currentTool?.id === 2"
      v-model="showTool"
      :src="hdUrl"
      @change="updateSrc"
    />
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </Attr>
</template>
<style scoped>
:deep(.color-picker canvas) {
  border: none !important;
}
:deep(.reference-container) {
  width: 100%;
}
.reference-container .form {
  border-bottom: 1px solid #e8e9ec;
}

.tool-container .tool-item {
  height: 56px;
  width: 100%;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e9ec;
  cursor: pointer;
  transition: background-color 250ms;
}

.tool-container .tool-item:hover {
  background-color: #e5e7eb;
}

.tool-item span {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
</style>
<style>
.el-popover.guide-popper .extra-container a {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration: underline;
}
</style>
