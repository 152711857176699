<script setup lang="ts">
import { useHead } from "@vueuse/head";
import PricingCardBox from "../card/pricing-card-box.vue";
import SubscribeDetailDialog from '../modal/subscribing.vue';
import PricingModal from "../modal/modal.vue";
import Faq from "./faq.vue";
import Header from "./header.vue";
import {
  cancelSubscription,
  recoverSubscription,
} from "@/api/svPremium";
import { getToken, isLogin } from "@/utils/auth";
import type {
  CardItemInfo,
  ModalArguments,
  ModalName,
  ModalInfo,
} from "../../type";
import { useSubscriptionInfo } from "@/store/modules/user";
import { useTrackStore } from "@/store/modules/track";
import { usePricingStore } from "../../stores";

const emit = defineEmits(['next', 'close', 'cancel'])
const props = defineProps({
  subscriptionType: String,
  state: String,
  refreshSubscribeInfo: Function
})

const route = useRoute();
const router = useRouter();
useHead({
  title: route.meta.title,
  meta: [
    {
      property: "og:title",
      content: route.meta.title,
    },
    {
      name: "twitter:title",
      content: route.meta.title,
    },
  ],
});

const { 
  refresh,
  updateSubscriptionState
} = useSubscriptionInfo();
const { 
  subscriptionState 
} = storeToRefs(useSubscriptionInfo());
const { 
  collectData, 
  track, 
  getTrackPlanName 
} = useTrackStore();
const {
  cardList,
  getCardInfo,
} = usePricingStore();

const isLoading = ref(true);
// 弹窗参数
const modalArguments: ModalArguments = reactive({
  type: "subscribeSuccess",
  card: null,
  visible: false,
});
// 月末是否支付状态
const isPaid = ref(true);

const subscribeDetDialogVisible = ref(false);
const subscribingStep = ref(1);
const handleDetailClose = () => {
  subscribeDetDialogVisible.value = false;
};

provide("isPaid", isPaid);

// 判断是否订阅成功跳转
watchEffect(() => {
  const subscriptionType = props.subscriptionType;
  (async () => {
    switch (subscriptionType) {
      case "NEW_SUBSCRIPTION":
        await nextTick();
        if(props.state != 'view') {
          userOperationMap.subscribeSuccess();
        }
        break;
      case "UPGRADE_SUBSCRIPTION":
        await nextTick();
        if (props.state == 'success') {
          userOperationMap.upgradeSuccess();
        }
        break;
      case "DEGRADE_SUBSCRIPTION":
        await nextTick();
        if (props.state == 'success') {
          userOperationMap.downgradeSuccess();
        }
        break;
      default:
        break;
    }
  })();
});

onMounted(async () => {
  isLoading.value = true;
  // 更新订阅状态
  if (isLogin.value) {
    await updateSubscriptionState();
  }
  isLoading.value = false;
});

// 点击订阅
const handleSubscribe = (card: CardItemInfo) => {
  collectData("boolvideo_subscribe_click", {
    click: "click_subscribe",
    plan_name: getTrackPlanName(card.packageCode),
  });
  track("boolvideo_subscribe_click");

  emit('next', { path: 'payment', packageCode: card.packageCode, subscriptionType: "NEW_SUBSCRIPTION", state: 'view' })
};

const handleNext = (data: any) => {
  emit('next', data)
}

const canCancel = computed(() => {
  const state = subscriptionState.value;
  const canCancelCode = [300204, 300205, 300304,300305, 300404, 300405, -1 ]

  return !canCancelCode.includes(state.packageCode) && !state.cancelSubAtPeriodEndBoolean
})

// 点击取消订阅逻辑
const handleCancel = async () => {
  collectData("boolvideo_subscribe_cancel", {
    click: "cancel_confirm",
    plan_name: getTrackPlanName(subscriptionState.value.packageCode),
  });
  return new Promise((resolve, reject) => {
    try {
      cancelSubscription().then(async (res) => {
        const { code } = res;
        if (code === 0) {
          await refresh();
          await updateSubscriptionState();
          router.replace({ query: {} });
          const o = window.opener;
          if (o && o.location.host === window.location.host) {
            const m = {
              subscribeSuccess: "true",
              token: getToken(),
            };
            window.opener.postMessage(m, o.location.origin);
          }
          track("boolvideo_subscribe_cancel_confirm");
          track("boolvideo_subscribe_cancel");
          openModal("cancelSuccess");
          resolve(res);
        } else {
          reject(res);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

// 点击升级
const handleUpgrade = (card: CardItemInfo) => {
  collectData("boolvideo_subscribe_change", {
    click: "click_upgrade",
    plan_name: getTrackPlanName(card.packageCode),
  });
  track("boolvideo_subscribe_change");

  emit('next', { path: 'payment', packageCode: card.packageCode, subscriptionType: "UPGRADE_SUBSCRIPTION" })
};

const handleSubscribing = (card: CardItemInfo, step: number = 1) => {
  subscribeDetDialogVisible.value = true;
  subscribingStep.value = step;
};

const handleDowngrade = (
  card: CardItemInfo,
  info: ResData.BoolvideoUpdateSubPopMsg,
) => {
  collectData("boolvideo_subscribe_change", {
    click: "click_downgrade",
    plan_name: getTrackPlanName(card.packageCode),
  });
  track("boolvideo_subscribe_change");
  openModal("downgrade", { card, downgradeInfo: info });
};

const handleSubscribeSuccess = () => {
  updateSubscriptionState();
  emit('close')
  openModal("subscribeSuccess");
};

const handleUpgradeSuccess = () => {
  openModal("upgradeSuccess");
};

const handleDowngradeSuccess = () => {
  emit('close')
  openModal("downgradeSuccess");
};

const handleContactUs = () => {
  collectData("boolvideo_subscribe_click", {
    click: "click_subscribe",
    plan_name: "contact_us",
  });
  track("boolvideo_subscribe_click");
  openModal("contactUs");
};

const handleCancelSuccess = () => {
  openModal("cancelSuccess");
};

const handleRecover = async () => {
  await recoverSubscription().then((res) => {
    if (res.code === 0) {
      const cardInfo = getCardInfo(subscriptionState.value.packageCode);
      const pricingArr = cardInfo.monthlyPrice?.split('$') || []
      gtag("event", `click_resume_success`, {
        type:
          cardInfo.subscriptionCycle == "MONTH"
            ? "month"
            : cardInfo.subscriptionCycle == "YEAR"
              ? "year"
              : "",
        package_code: cardInfo.packageCode,
        price: Number(pricingArr[1]),
        currency: 'USD'
      });
      userOperationMap.recoverSuccess();
    }
  });
};

const handleRecoverSuccess = () => {
  openModal("recoverSuccess");
};

const handleYearToMonthTips = () => {
  openModal("yearToMonth");
};

const handleLtdTips = () => {
  openModal("ltdTips");
};

const handleRefundSuccess = () => {
  openModal("ltdRefundSuccess");
};

const openModal = (_type: ModalName, otherInfo?: ModalInfo) => {

  modalArguments.type = _type;
  if (otherInfo?.card) {
    modalArguments.card = otherInfo.card;
  }
  if (otherInfo?.downgradeInfo) {
    modalArguments.downgradeInfo = otherInfo.downgradeInfo;
  }
  if (otherInfo?.giftInfo) {
    modalArguments.giftInfo = otherInfo.giftInfo;
  }
  if (otherInfo?.subscribingInfo) {
    modalArguments.subscribingInfo = otherInfo?.subscribingInfo;
  }
  modalArguments.visible = true;
};

const modalCloseCallback = (e: string) => {
  modalArguments.visible = false;
  refresh()
};


const userOperationMap = {
  subscribe: handleSubscribe,
  subscribeSuccess: handleSubscribeSuccess,
  upgradeSuccess: handleUpgradeSuccess,
  subscribing: handleSubscribing,
  upgrade: handleUpgrade,
  downgrade: handleDowngrade,
  downgradeSuccess: handleDowngradeSuccess,
  cancel: handleCancel,
  cancelSuccess: handleCancelSuccess,
  recover: handleRecover,
  recoverSuccess: handleRecoverSuccess,
  contactUs: handleContactUs,
  yearToMonthTips: handleYearToMonthTips,
  ltdTips: handleLtdTips,
  ltdRefundSuccess: handleRefundSuccess,
};
</script>

<template>
  <Header @close="$emit('close')"/>
  <div class="content-container">
    <el-scrollbar>
      <div class="premium-container" v-track:exposure="'boolvideo_pricing_view'">
        <div class="premium-inner">      
          <PricingCardBox :cardList="cardList" :subscriptionState="subscriptionState" :isLoading="isLoading"
            :userOperationMap="userOperationMap" />
        </div>
        <Faq :canCancel="canCancel" :userOperationMap="userOperationMap" />
      </div>
    </el-scrollbar>
  </div>
  
  <PricingModal :modalArguments="modalArguments" :closeCallback="() => modalCloseCallback()"
    :userOperationMap="userOperationMap" @next="handleNext" />
    
  <SubscribeDetailDialog
    v-if="subscribeDetDialogVisible"
    @close="handleDetailClose"
    :closeCallback="handleDetailClose"
    :directToPricing="true"
  />
</template>

<style lang="scss" scoped>
.content-container {
  height: 680px;
}

.premium-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  user-select: text;
}

.premium-inner {
  position: relative;
  width: 100%;
  background: #e5eaff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.premium-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  color: #000;
  margin-top: 76px;
}

.premium-tips {
  font-weight: 600;
  margin-top: 24px;
}

.switcher {
  display: flex;
  align-items: center;
  padding: 3px;
  margin-top: 36px;
  border-radius: 9999px;
  color: #000;
  background: #fff;
  border: 2px solid #313131;
  font-size: 16px;
  font-weight: 500;

  &>li {
    min-width: 180px;
    padding: 15px 20px;
    background-color: transparent;
    transition: background-color 0.2s;
    border-radius: 9999px;
    cursor: pointer;
    user-select: none;
    text-align: center;

    &.active {
      background-color: #000;
      color: #fff;
    }

    &>img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}
</style>