import { defineStore } from "pinia";
import { sessionGetItem, sessionSetItem, sessionRemoveItem } from '@/utils/storage';
import { generateSimilarScript, getSimilarHookList, getSimilarStickersList, getSimilarVoiceList, renderSimilarVideo, getSimilarViralHook } from '@/api/similarVideo/index.js';
import { useMessage } from '@/utils';

const originParams = sessionGetItem('similarVideo');
const mediaList = sessionGetItem('cacheMediaList');
const scriptData = sessionGetItem('scriptData');
const selectedScriptItem = sessionGetItem('selectedScript');
const styleId = sessionGetItem('selectedStyleId') ? sessionGetItem('selectedStyleId') : 1;
const mediaId = sessionGetItem('selectedMediaId');
const voiceId = sessionGetItem('selectedVoiceId');
const collection = sessionGetItem('currentCollection');
const type = sessionGetItem('selectedType');
const message = useMessage();

export const useSimilarVideo = defineStore("similarVideo", {
  state: () => ({
    reqId: null,
    checkSource: true,

    formParams: originParams, // 用户表单信息
    generateScript: scriptData, // 初次生成的脚本
    selectedScriptItem: selectedScriptItem, // 用户选择的用于生成的脚本

    errorMessage: '',
    fetchCode: 0,
    completedFetch: false,

    pagination: {
      page: 1,
      size: 20,
      loadAll: false,
    },
    hookList: [],
    stickerList: [],
    voiceList: [],
    viralHookList: [],
    fetchLoading: false,
    collectionList: [],
    currentCollection: collection,

    selectedStyleId: styleId || 1,
    selectedMediaId: mediaId,
    selectedType: type || 0,
    selectedVoiceId: voiceId,

    cacheMediaList: mediaList,
  }),

  actions: {
    setMediaList (params) {
      this.cacheMediaList = params
      sessionSetItem('cacheMediaList', params);
    },

    setFormParams (params) {
      this.formParams = params;
      sessionSetItem('similarVideo', params);
    },

    setGenerateScript (scripts) {
      this.generateScript = scripts
      sessionSetItem('scriptData', scripts);
    },

    setSelectedScript (script) {
      this.selectedScriptItem = script;
      sessionSetItem('selectedScript', script);
    },


    setSelectedStyleId (id) {
      this.selectedStyleId = id;
      sessionSetItem('selectedStyleId', id);
    },

    setSelectedMediaId (id) {
      this.selectedMediaId = id;
      sessionSetItem('selectedMediaId', id);
    },

    setSelectedType (id) {
      this.selectedType = id;
      sessionSetItem('selectedType', id);
    },

    setSelectedVoiceId (id) {
      this.selectedVoiceId = id;
      sessionSetItem('selectedVoiceId', id);
    },

    setCurrentCollection (item) {
      this.currentCollection = item;
      sessionSetItem('currentCollection', item);
    },

    resetPagination () {
      this.pagination = {
        page: 1,
        size: 20,
        loadAll: false,
      }
    },

    resetList () {
      this.collectionList = []
      
    },

    removeSimilarEditCache () {
      this.currentCollection = null
      this.selectedMediaId = null
      this.selectedVoiceId = null
      sessionRemoveItem('selectedStyleId')
      sessionRemoveItem('selectedMediaId')
      sessionRemoveItem('currentCollection')
      sessionRemoveItem('selectedVoiceId')
      sessionRemoveItem('selectedScript')
      sessionRemoveItem('scriptData')
      sessionRemoveItem('cacheMediaList')
      sessionRemoveItem('selectedType')
      this.resetPagination()
    },

    removeSimilarVideoCache () {
      this.formParams = null
      this.generateScript = null
      this.selectedScriptItem = null
      this.currentCollection = null
      this.selectedMediaId = null
      this.selectedVoiceId = null
      sessionRemoveItem('similarVideo')
      sessionRemoveItem('scriptData')
      sessionRemoveItem('selectedScript')
      sessionRemoveItem('selectedStyleId')
      sessionRemoveItem('selectedMediaId')
      sessionRemoveItem('currentCollection')
      sessionRemoveItem('selectedVoiceId')
      sessionRemoveItem('cacheMediaList')
      sessionRemoveItem('selectedType')
      this.resetPagination()
    },

    async generateSimilarScriptFn (params) {
      // 存储参数
      this.completedFetch = false
      this.errorMessage = ''
      this.setFormParams(params)
      const { msg, code, data } = await generateSimilarScript(params);

      this.completedFetch = true
      this.fetchCode = code

      if (code == 130506 || code == 130503) {
        this.errorMessage = msg;
        return;
      }

      if (code != 0) {
        message.error('Rewrite failed, try again');
        return;
      }

      this.setGenerateScript(data)
    },

    async getSimilarHookListFn () {

      if (this.pagination.page == 1) {
        this.fetchLoading = true
        this.collectionList = []
        this.hookList = []
      }

      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        style: 1,
        type: this.selectedType,
      }

      const { msg, code, data, total } = await getSimilarVoiceList(params)
      this.fetchLoading = false
      if (code != 0) {
        message.error('error, try again');
        return
      }

      if (!data.records || !data.records?.length || this.hookList.length >= total) {
        return
      }

      this.pagination.page += 1
      this.pagination.loadAll = data.current * data.size > data.total;
      this.hookList = [...this.hookList, ...data.records]
      this.collectionList = [...this.collectionList, ...data.records]
    },

    async getSimilarStickersListFn () {
      if (this.pagination.page == 1) {
        this.fetchLoading = true
        this.collectionList = []
        this.stickerList = []
      }

      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        style: 3,
        type: this.selectedType,
      }

      const { msg, code, data, total } = await getSimilarVoiceList(params)
      this.fetchLoading = false
      if (code != 0) {
        message.error('error, try again');
        return
      }

      if (!data.records || !data.records?.length || this.stickerList.length >= total) {
        return
      }

      this.pagination.page += 1
      this.pagination.loadAll = data.current * data.size > data.total;
      this.stickerList = [...this.stickerList, ...data.records]
      this.collectionList = [...this.collectionList, ...data.records]
    },

    async getSimilarVoiceListFn () {

      if (this.pagination.page == 1) {
        this.fetchLoading = true
        this.collectionList = []
        this.voiceList = []
      }

      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        style: 2,
        type: this.selectedType,
      }

      const { msg, code, data, total } = await getSimilarVoiceList(params)
      this.fetchLoading = false
      if (code != 0) {
        message.error('error, try again');
        return
      }

      if (!data.records || !data.records?.length || this.voiceList.length >= total) {
        return
      }

      this.pagination.page += 1
      this.pagination.loadAll = data.current * data.size > data.total;
      this.voiceList = [...this.voiceList, ...data.records]
      this.collectionList = [...this.collectionList, ...data.records]
    },


    async getSimilarViralHookFn () {
      
      if (this.pagination.page == 1) {
        this.fetchLoading = true
        this.collectionList = []
        this.viralHookList = []
      }

      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
      }

      const { msg, code, data, total } = await getSimilarViralHook(params)
      this.fetchLoading = false

      if (code != 0) {
        message.error('error, try again');
        return
      }

      if (!data.records || !data.records?.length || this.viralHookList.length >= total) {
        return
      }

      this.pagination.page += 1
      this.pagination.loadAll = data.current * data.size > data.total;
      this.viralHookList = [...this.viralHookList, ...data.records]
      this.collectionList = [...this.collectionList, ...data.records]

    },
  },

  getters: {
    getFormParams: (state) => {
      return state.formParams || originParams
    },
    getGenerateScript: (state) => {
      return state.generateScript || scriptData
    },
    getSelectedScript: (state) => {
      return state.selectedScriptItem || selectedScriptItem
    },
    getStyleId: (state) => {
      return state.selectedStyleId || styleId
    },
    getMediaId: (state) => {
      return state.selectedMediaId || mediaId
    },
    getVoiceId: (state) => {
      return state.selectedVoiceId || voiceId
    },
  },
});