<template>
  <el-dialog
    class="subscribe-container"
    align-center
    destroy-on-close
    v-model="dialogVisible"
    :append-to-body="true"
    :show-close="false"
    :width="modalWidth"
    @close="handleCloseDialog"
  >
    <component
      :is="tabs[currentTab]" 
      :showIntroduction="showIntroduction"
      @close="handleCloseDialog"
    />
  </el-dialog>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import BoolvideoPremium from "./premium/index.vue";
import SimilarPremium from "./svpremium/index.vue";

const emit = defineEmits(['close', 'refresh']);
const props = defineProps({
  visible: Boolean,
  showIntroduction: Boolean,
});

const tabs = {
  1: BoolvideoPremium,
  2: SimilarPremium,
};
const currentTab = inject("globalDomain");
const modalWidth = ref(1060);
const dialogVisible = ref(props.visible);

watch(
  () => props.visible,
  (visible) => {
    dialogVisible.value = visible;
  },
  {
    immediate: true,
  }
);

const handleCloseDialog = () => {
  dialogVisible.value = false;
  emit('close', true);
};


const computedModalWidth = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth > 4000) {
    modalWidth.value = 2500;
  }else if (screenWidth > 3000 && screenWidth < 4000) {
    modalWidth.value = 2000;
  } else if (screenWidth > 2400 && screenWidth < 3000) {
    modalWidth.value = 1500;
  } else {
    modalWidth.value = 1060;
  }
};

onMounted(() => {
  computedModalWidth();
  window.onresize = () => {
    computedModalWidth();
  };
});
</script>

<style lang="scss" scoped>
.subscribe-container {
  @media screen and (min-width: 2600px) and (max-width: 3000px) {
    .content-container {
      height: 1000px;
    }
  }

  @media screen and (min-width: 3000px) and (max-width: 4000px) {
    .content-container {
      height: 1200px;
    }
  }


  @media screen and (min-width: 4000px) {
    .content-container {
      height: 1500px;
    }
  }
}

.card-part {
  background: #e5eaff;
  height: 200px;
}
</style>

<style lang="scss">
.subscribe-container {
  &.el-dialog {
    min-width: 507px;
    // max-height: 1200px;
    border-radius: 4px;

    & > .el-dialog__header {
      padding: 0;
      margin: 0;
    }

    & > .el-dialog__body {
      padding: 0px 0px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    & > .el-dialog__footer {
      padding: 20px;
    }
  }

  .el-scrollbar__wrap {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
