import { useCreatorStore, useDragStore } from "../stores";
import { getComputedSize } from "../utils";
import { defaultFontFamily } from "@/constants/text";

export default {
  mounted(element, binding) {
    const dragData = useDragStore();
    const { activeNodeMap, getXY, secondToFrame, addNode, addTransitionNode } =
      useCreatorStore();
    const {
      type,
      file,
      option,
      selector,
      stopPropagation,
      trigger = "drag-click",
      beforeMouseDown = () => true,
      callback,
    } = binding.value;

    const click = async () => {
      if (type === "transition") {
        const success = await addTransitionNode(dragData.data, undefined, {
          shouldRefresh: true,
        });
        callback(success);
      } else {
        await addNode(dragData.data, {
          shouldRefresh: true,
          shouldAnnotate: true,
        });
        callback();
      }
    };
    const mouseUp = async () => {
      dragData.timelineEnterd = false;
      document.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);

      if (
        trigger.includes("click") &&
        (!dragData.target || dragData.canvasMouseUp)
      ) {
        dragData.canvasMouseUp = false;
        await click();
      }
      if (dragData.target) {
        if (dragData.target.parentElement) {
          dragData.target.parentElement.removeChild(dragData.target);
        }
        dragData.target = null;
      }
    };
    const mouseMove = (e) => {
      if (!dragData.target) {
        activeNodeMap.clear();

        const currentTarget = selector
          ? element.querySelector(selector)
          : element;
        const [width, height] = getComputedSize(currentTarget);

        dragData.target = currentTarget.cloneNode(true);

        if (["image", "video"].includes(type)) {
          const replaceTip = document.createElement("div");
          replaceTip.innerText = "Replace";
          replaceTip.classList.add("replace-tip");

          Object.assign(replaceTip.style, {
            display: "none",
            position: "absolute",
            top: "12px",
            left: "50%",
            transform:" translate(-50%, 0)",
            padding: "2.5px 5px",
            fontSize: "12px",
            lineHeight: "14px",
            color: "#FFF",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            borderRadius: "2px",
          });
          dragData.target.appendChild(replaceTip);
        }
        Object.assign(dragData.target.style, {
          position: "fixed",
          transform: "translate(-50%, -50%)",
          zIndex: 10,
          pointerEvents: "none",
          width: `${width}px`,
          height: `${height}px`,
          borderRadius: "4px",
          backgroundColor: "rgba(100,100,100,.12)",
        });
        document.body.appendChild(dragData.target);
      }
      dragData.target.style.left = `${e.pageX}px`;
      dragData.target.style.top = `${e.pageY}px`;
    };
    const mouseDown = (e) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
      if ((file.state && file.state !== 0) || e.button !== 0) {
        return;
      }
      if (!beforeMouseDown()) {
        return;
      }
      switch (type) {
        case "image":
          dragData.data = {
            ...getXY(),
            type,
            sourceId: file.id,
            src: file.previewUrl,
            fit: "contain",
            duration: 90,
            hdUrl: file.preview1080Url,
            materialMeta: {
              width480: file.width480,
              width1080: file.width1080,
              url1080: file.preview1080Url,
            },
            ...option,
          };
          break;
        case "video":
          dragData.data = {
            ...getXY(),
            type,
            sourceId: file.id,
            src: file.previewUrl,
            fit: "contain",
            duration: secondToFrame(file.duration),
            coverPic: file.coverPic,
            hdUrl: file.preview1080Url,
            transparent: file.aiType === "videoBgRemove",
            materialMeta: {
              width480: file.width480,
              width1080: file.width1080,
              url1080: file.preview1080Url,
            },
          };
          break;
        case "audio":
          dragData.data = {
            type,
            name: file.name,
            sourceId: file.id,
            src: file.previewUrl || file.url,
            duration: secondToFrame(file.duration),
          };
          break;
        case "text":
          dragData.data = {
            ...getXY(),
            type,
            fontFamily: defaultFontFamily,
            fill: "#000000",
            duration: 90,
            ...file,
          };
          break;
        case "graphic":
          dragData.data = {
            ...getXY(),
            type,
            name: file.name,
            shape: file.shape,
            fill: file.fill,
            width: file.width,
            height: file.height,
            duration: 90,
          };
          break;
        case "sticker":
          dragData.data = {
            ...getXY(),
            type,
            coverPic: file.coverPic,
            sourceId: file.id,
            src: file.url,
            scale: 0.5,
            mode: file.type === 1 ? "animated" : "static",
            duration: 90,
            materialMeta: {
              width480: file.width480,
              width1080: file.width1080,
              url1080: file.preview1080Url,
            },
          };
          break;
        case "effect":
          dragData.data = {
            ...getXY(),
            type,
            fit: "cover",
            loop: true,
            name: file.name,
            src: file.url,
            blend: file.blend,
            sourceId: file.id,
            duration: 90,
            materialMeta: {
              width480: file.width480,
              width1080: file.width1080,
              url1080: file.preview1080Url,
            },
          };
          break;
        case "transition":
          dragData.data = {
            type,
            name: file.name,
            sourceId: file.id,
            duration: 90,
          };
          break;
        case "filter":
          dragData.data = {
            type,
            name: file.name,
            src: file.url,
            sourceId: file.id,
            duration: 90,
          };
          break;
      }
      file.type = type;
      dragData.file = file;
      window.addEventListener("mouseup", mouseUp);

      if (trigger.includes("drag")) {
        document.addEventListener("mousemove", mouseMove);
      }
    };
    element.addEventListener("mousedown", mouseDown);
  },
};
