<script setup>
import Header from "./header.vue";
import Content from "./content.vue";
import Loading from "./loading.vue";
import Assistance from "@/components/ai-assistant/assistance.vue";
import defaultConfig from "@/assets/data/conf.json";
import debugConfig from "@/assets/data/debug.json?raw";
import { getVideoRenderInfo, getDraftDetail, putDraft } from "@/api/draft";
import { useTrackStore } from "@/store/modules/track";
import { useMessage, useRetryAsync } from "@/utils";
import { useNetwork } from "@/composables";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useKeyboard,
} from "../../stores";
import VideoCliper from "./video-cliper.vue";
import MaterialDialog from "../../../clipEditor/components/dialog/materialdialog.vue";

const {
  title,
  videoType,
  setRatio,
  setVideoType,
  setVideoSubtype,
  setDraftId,
  setReqId,
  setRid,
  updateDraft,
} = useDraftStore();
const {
  replaceData,
  showMaterialDialog,
  creator,
  start,
  destroy,
  replaceNode,
} = useCreatorStore();
const { commit } = useHistoryStore();
const { collectData, track } = useTrackStore();
const route = useRoute();
const router = useRouter();
const message = useMessage();
const { onLine } = useNetwork();
const globalDomain = inject("globalDomain");
const enterTime = ref(0);
const renderCompleteTime = ref(0);

useKeyboard(true);
const formateReportData = ({
  videoType,
  draftId,
  rid,
  reqId,
  enterFrom,
  loadingTime,
  result,
}) => {
  return {
    editor_type: "timeline_editor",
    video_type: videoType || videoType.value,
    draftId: draftId || "",
    rid: rid || "",
    reqId: reqId || "",
    enter_type: enterFrom ? enterFrom : "generate",
    editor_type: "timeline",
    load_time: loadingTime,
    result: result || "success",
  };
};
function handleTrack(data) {
  const { draftId, rid, reqId, result } = data;
  renderCompleteTime.value = new Date();
  const reportData = formateReportData({
    videoType: videoType.value,
    draftId,
    rid,
    reqId,
    enterFrom: route.query?.enterFrom,
    loadingTime: renderCompleteTime.value - enterTime.value,
    result,
  });
  collectData("boolvideo_editor_page_view", reportData);
  track("boolvideo_editor_page_view");
}

onMounted(() => {
  window.addEventListener("beforeunload", () => {
    if (!creator.value) {
      // 中途离开上报
      handleTrack({ result: "leave" });
    }
  });
  setup();
});
onBeforeUnmount(() => {
  destroy();
  window.removeEventListener("beforeunload", handleTrack);
});

watch(onLine, (newOnLine) => {
  if (!newOnLine) {
    message.error("Editing can not be synchronized", { showClose: true });
  }
});

function submit() {
  commit();
  updateDraft();
}

async function setup() {
  enterTime.value = new Date();
  const trackHelper = (value) => {
    const { renderType, draftId, reqId, rid } = value;
    setVideoType(renderType);
    setVideoSubtype(renderType);
    setDraftId(draftId);
    setReqId(reqId);
    setRid(rid);
  };

  const { debug } = route.query;
  let rid = route.query.rid || "";
  let draftId = route.query.draftId;
  let reqId = "";
  let renderConfig = JSON.stringify(defaultConfig);
  let duration = 0;
  let ratio = "9:16";
  let formattingId = [];
  let scriptId = 0;
  let sourceRatio = ratio;

  if (rid) {
    const { success, data } = await getVideoRenderInfo(rid);

    if (!success) {
      return;
    }
    trackHelper(data);
    draftId = data.draftId;
    reqId = data.reqId;
    renderConfig = data.renderConfig;
    duration = data.duration;
    sourceRatio = ratio = data.ratio;
    formattingId = data.formattingId;
    scriptId = data.scriptId;
  }
  if (!draftId) {
    const { success, data } = await putDraft({
      rid,
      renderConfig,
      duration,
      ratio,
    });

    if (!success) {
      handleTrack({ result: "fail" });
      return;
    }
    draftId = data.draftId;
  } else {
    if (debug) {
      await start(debugConfig, sourceRatio);
      return;
    }

    const { success, data } = await getDraftDetail(draftId);

    if (!success) {
      handleTrack({ result: "fail" });
      return;
    }

    trackHelper(data);
    rid = data.rid;
    reqId = data.reqId;
    renderConfig = data.renderConfig;
    title.value = data.name;
    ratio = data.ratio;
    formattingId = data.formattingId;
    scriptId = data.scriptId;
    sourceRatio = data.sourceRatio;
  }

  collectData("boolvideo_export_click", {
    ratio: ratio.replace(":", "_"),
    video_type: videoType.value,
    formatting_id: formattingId || [],
    script_id: scriptId || 0,
    editor_type: "timeline_editor",
    draftId,
    reqId,
    rid,
  });

  collectData("boolvideo_timeline_edit_click", {
    draftId,
    reqId,
    rid,
    video_type: videoType.value,
  });

  router.replace({ query: { ...route.query, rid: undefined, draftId } });
  setRatio(ratio);
  const retryStart = useRetryAsync(start);
  await retryStart(renderConfig, sourceRatio);
  handleTrack({ draftId, reqId, rid });
}

function handleReplace(file) {
  replaceData.showBack = true;
  replaceNode(replaceData.node, file).then((success) => {
    if (success) {
      submit();
      message.success("Processing completed");
      collectData("boolvideo_timeline_edit_click", {
        click: "replace_media",
      });
      track("boolvideo_timeline_edit_click");
    }
  });
  setTimeout(() => {
    showMaterialDialog.value = false;
  }, 300);
}
</script>
<template>
  <div class="editor-layout">
    <Header />
    <Content />
    <assistance v-if="globalDomain === 1" :loading="!creator" />
    <loading v-if="!creator" />
    <bv-overlay
      :model-value="showMaterialDialog || replaceData.showVideoCliper"
      @close="showMaterialDialog = replaceData.showVideoCliper = false"
    >
      <material-dialog v-model="showMaterialDialog" @confirm="handleReplace" />
      <video-cliper
        v-model="replaceData.showVideoCliper"
        :show-back="replaceData.showBack"
        :src="replaceData.src"
        :poster="replaceData.poster"
        :duration="replaceData.duration"
        :transparent="replaceData.transparent"
        :apply="replaceData.apply"
      />
    </bv-overlay>
  </div>
</template>

<style scoped>
.editor-layout {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

:deep(.el-slider__button-wrapper.dragging),
:deep(.el-slider__button.dragging),
:deep(.el-slider__button-wrapper:hover),
:deep(.el-slider__button:hover) {
  cursor: pointer;
}
</style>
