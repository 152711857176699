<script setup>
import Material from "./material.vue";
import { useDraftStore, useHistoryStore } from "../../stores";
import vDragClick from "../../directives/v-drag-click";

const shapeOptions = [
  {
    name: "Rectangle",
    shape: "rect",
    fill: "#CACDD0",
    icon: "editor_rectangle",
    width: 300,
    height: 150,
    size: 46,
  },
  {
    name: "Circle",
    shape: "circle",
    fill: "#CACDD0",
    icon: "editor_circle",
    width: 250,
    height: 250,
    size: 50,
  },
  {
    name: "Triangle",
    shape: "triangle",
    fill: "#CACDD0",
    icon: "editor_triangle",
    width: 250,
    height: 216,
    size: 58,
  },
];

const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

function submit() {
  commit();
  updateDraft();
}
</script>
<template>
  <Material title="Graphic">
    <el-scrollbar>
      <div class="material-content">
        <div class="shape-list">
          <div v-for="file in shapeOptions" class="shape-item" :key="file.name">
            <div
              class="image-wrapper"
              v-drag-click="{ file, type: 'graphic', callback: submit }"
            >
              <svg-icon
                :name="file.icon"
                :color="file.fill"
                :size="file.size"
              />
            </div>
            <div class="title">{{ file.name }}</div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style scoped>
.shape-list {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));
  padding: 16px 0;
}
.image-wrapper {
  width: 100%;
  height: 68px;
  padding: 7px;
  border: 1px solid #e8e9ec;
  border-radius: 4px;
  overflow: hidden;
  transition: border-color 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.image-wrapper:hover {
  border-color: #875eff;
}

.title {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  margin-top: 8px;
}
</style>
