import CommonButton from "@/components/common/bv-button/components/common-button.vue";
import PrimaryButton from "@/components/common/bv-button/components/primary-button.vue";
import Space from "@/components/common/space/index.vue";
import { SvgIcon } from "@/components/common";
import { retryPayment } from "@/api/premium";
import { asyncDebounce } from "@/utils/debounce";
import type { CSSProperties, VNode } from "vue";
import type { Modal, DefaultProps } from "../type";
import { openPremiumInTab } from "@/utils/premium";
import { useTrackStore } from "@/store/modules/track";
import { useSubscriptionInfo } from "@/store/modules/user";
import { useMessage } from "@/utils";

interface ButtonProps {
  content: string | VNode;
  style?: CSSProperties;
}

type BoolvDefaultProps = Omit<DefaultProps, 'footer' | 'icon'> & {
  onHandle: () => void;
  mainButton: ButtonProps;
  cancelButton?: ButtonProps;
};

namespace BoolvTemplateProps {
  export type Confirm = {
    loading?: boolean;
    showCancel?: boolean;
    confirmText?: string;
    onConfirm?: () => void;
    closeAfterConfirm: boolean;
  } & Pick<Modal, "title" | "content" | "zIndex"> &
    Partial<Pick<Modal, "icon" | "modalClass" | "key">>;

  export type UpgradeTips = {
    /**
     * ```typescript
     * enum code {
     *   20001: 'export',
     *   30001: 'premium_assets',
     *   40001: 'cloud',
     *   50001: 'credits',
     *   60001: 'brandkit',
     * }
     * ```
     */
    code: 20001 | 30001 | 40001 | 50001 | 60001 | (number & {});
    msg?: string;
    loading?: boolean;
    zIndex?: number;
    onConfirm?: () => void;
  } & Partial<Pick<Modal, 'title' | 'content' | 'icon' | 'modalClass' | 'key'>>;

  export type ImportFailed = {
    msg: string;
    loading?: boolean;
  } & Partial<Pick<Modal, "title" | "content" | "icon" | "modalClass" | "key">>;

  export type PaymentFail = {
    repaySuccessCallback?: () => void;
    doneCallback?: () => void;
    zIndex?: number,
    redirectUrl?: string,
  };

  export type PaymentAuthentication = {
    doneCallback?: () => void;
    zIndex?: number,
    redirectUrl?: string,
  }

  export type SingleConfirm = {
    loading?: boolean;
    showCancel?: boolean;
    confirmText?: string;
    onConfirm?: () => void;
    closeAfterConfirm: boolean;
    zIndex?: number;
  }& Partial<Pick<Modal, "title" | "content" | "icon" | "modalClass" | "key">>;  
}

const templates = {
  warning(this: Modal, props: BoolvTemplateProps.Confirm) {
    const {
      loading = false,
      showCancel = true,
      confirmText = 'OK',
      modalClass,
      title,
      content,
      icon = null,
      closeAfterConfirm = true,
    } = props;
    let confirming = false;

    const confirm = () => {
      confirming = true;
      props.onConfirm?.();
      closeAfterConfirm && (this.open = false);
    };
    this.$resetAndPatch({
      open: true,
      key: props.key || '',
      modalClass,
      title,
      content,
      icon,
      footer: (
        <Space size={12} reversed>
          <PrimaryButton
            size="extra-small"
            onclick={confirm}
            loading={loading}
            fontColor="#FFF"
            style={{ background: '#FF5449' }}>
            {confirmText}
          </PrimaryButton>
          {showCancel && (
            <CommonButton size="extra-small" onclick={this.onClose}>
              Cancel
            </CommonButton>
          )}
        </Space>
      ),
    });
    this.onClose = () => {
      !confirming && (this.open = false);
    };
  },
  confirm(this: Modal, props: BoolvTemplateProps.Confirm) {
    const {
      loading = false,
      showCancel = true,
      confirmText = 'OK',
      modalClass,
      title,
      content,
      icon = null,
    } = props;
    const confirm = () => {
      props.onConfirm?.();
      this.onClose();
    };

    this.$resetAndPatch({
      open: true,
      key: props.key,
      modalClass,
      title,
      content,
      icon,
      footer: (
        <Space size={12} reversed>
          <PrimaryButton size="extra-small" onclick={confirm} loading={loading}>
            {confirmText}
          </PrimaryButton>
          {showCancel && (
            <CommonButton size="extra-small" onclick={this.onClose}>
              Cancel
            </CommonButton>
          )}
        </Space>
      ),
    });
  },

  upgradeTips(this: Modal, props: BoolvTemplateProps.UpgradeTips) {
    const { track, collectData } = useTrackStore();

    const defaultText = {
      confirmText: 'Upgrade',
      content: (
        <p class="mt-6">
          {props.msg ||
            'Upgrade now to unlock all amazing features and take your experience to the next level.'}
        </p>
      ),
      title: 'Upgrade to use this feature',
    };
    const {
      loading = false,
      modalClass,
      title = defaultText.title,
      content = defaultText.content,
      icon = null,
      code,
      zIndex,
      key = "upgrade-tips-modal",
    } = props;

    const trackCTA = (eventName: string) => {
      const codeTrackMap = {
        20001: 'export',
        30001: 'premium_assets',
        40001: 'cloud',
        50001: 'ai_credits',
        60001: 'brand_kit',
        90001: 'voiceover_credit',
        90010: 'parsing_time',
        90011: 'voice',
        90012: 'parsing_size'
      };
      let viewTitle = '';
      if (code === 20001) {
        const pathname = window.location.pathname;
        if (
          pathname.includes('/template-video') ||
          pathname.includes('/editVideo')
        ) {
          viewTitle = 't_export';
        } else if (
          pathname.includes('/create-video') ||
          pathname === '/editor'
        ) {
          viewTitle = 'd_export';
        } else {
          const titleMap = {
            product_to_video: 'd_export',
            templates: 't_export',
            idea_to_video: 'idea_export',
            script_to_video: 'script_export',
            blog_to_video: 'blog_export',
            visuals_to_video: 'visual_export',
          };
          const queryString = window.location.search;
          // 创建URLSearchParams对象
          const params = new URLSearchParams(queryString);
          const videoType = params.get('videoType');

          if (videoType) {
            viewTitle = titleMap[videoType];
          }
        }
      } else {
        viewTitle = codeTrackMap[code as keyof typeof codeTrackMap];
      }
      collectData(eventName, {
        view_title: viewTitle,
      });
      track(eventName);
    };
    trackCTA('boolvideo_cta_view');
    const confirm = () => {
      trackCTA('boolvideo_cta_click');
      if (props.onConfirm) {
        props.onConfirm();
      } else {
        openPremiumInTab();
      }
      this.onClose();
    };

    this.$resetAndPatch({
      open: true,
      modalClass,
      title,
      content,
      icon,
      key,
      zIndex,
      footer: (
        <Space reversed>
          <PrimaryButton size="extra-small" onclick={confirm} loading={loading}>
            <span class="font-normal">{defaultText.confirmText}</span>
          </PrimaryButton>
        </Space>
      ),
    });
  },
  noCredits(this: Modal, props: any) {
    templates.upgradeTips.call(this, {
      msg: "You don't have enough credits. Upgrade now to get more credits.",
      code: 50001,
      ...props
    });
  },
  importFailed(this: Modal, props: BoolvTemplateProps.ImportFailed) {
    const { msg, key, modalClass, title } = props;

    const confirm = () => {
      this.onClose();
    };

    this.$resetAndPatch({
      open: true,
      key: key || '',
      modalClass,
      icon: 'error',
      title: title || 'Import failed',
      content: msg,
      footer: (
        <Space reversed>
          <PrimaryButton
            size="extra-small"
            onclick={confirm}
            fontColor="#FFF"
            // style={{ background: "#FF5449" }}
          >
            Got it
          </PrimaryButton>
        </Space>
      ),
    });
  },
  paymentFail(this: Modal, props: BoolvTemplateProps.PaymentFail) {
    const { zIndex = 2000, repaySuccessCallback, doneCallback } = props;
    const message = useMessage();
    const { refresh, updateSubscriptionState } = useSubscriptionInfo();
    const manageCard = () => {
      const { VITE_STRIPE_BILL } = import.meta.env;
      const { redirectUrl = VITE_STRIPE_BILL } = props;
      window.open(redirectUrl, '_blank');
    };
  
    const retry = asyncDebounce(async () => {
      const { code, msg } = await retryPayment();
      if (code === 0) {
        if (repaySuccessCallback) repaySuccessCallback();
        setTimeout(() => {
          refresh();
          updateSubscriptionState();
        }, 2000);
        message.success("Payment successful.");
        this.onClose();
      }
      else if (code === 103002){
        message.error(msg);
      }
      else {
        message.error("Payment fails. Please check your payment method.");
      }
    });
  
    const done = () => {
      setTimeout(() => {
        refresh();
        updateSubscriptionState();
      }, 2000);
      if (doneCallback) doneCallback();
      this.onClose();
    };
    this.$resetAndPatch({
      zIndex,
      open: true,
      modalClass: "payment-fail-modal",
      title: "Payment failed",
      key: "payment-fail-modal",
      content: (
        <>
          <p>Please check your credit card limit and availability. If your credit card is in good standing, click "Retry payment."</p>
          <p class="pt-3">If there is an issue with your card, click "Update payment method" to update your card information, and we will automatically retry the payment.</p>
        </>
      ),
      footer: (
        <Space size={12} reversed>
          <PrimaryButton 
            size="mini" 
            onclick={retry}
          >
            <span class="text-xs">Retry payment</span>
          </PrimaryButton>
          <CommonButton 
            size="mini" 
            onclick={manageCard}
          >
            <span class="text-xs">Update payment method</span>
          </CommonButton>
          <CommonButton
            size="mini"
            onclick={done}
          >
            <span class="text-xs">Done and refresh</span>
          </CommonButton>
        </Space>
      ),
    });
  },
  paymentAuthentication(this: Modal, props:  BoolvTemplateProps.PaymentAuthentication) {
    const { zIndex = 2000, doneCallback } = props;
    const { refresh, updateSubscriptionState } = useSubscriptionInfo();
    const authenticate = () => {
      const { VITE_STRIPE_BILL } = import.meta.env;
      const { redirectUrl = VITE_STRIPE_BILL } = props;
      window.open(redirectUrl, '_blank');
    };
    const done = () => {
      setTimeout(() => {
        refresh();
        updateSubscriptionState();
      }, 2000);
      if (doneCallback) doneCallback();
      this.onClose();
    };
    this.$resetAndPatch({
      zIndex,
      open: true,
      modalClass: "payment-authentication-modal",
      title: "Complete 3D Secure Authentication",
      key: "payment-authentication-modal",
      content: (
        <>
          <p>To finalize your payment, please complete 3D Secure (3DS) authentication. This extra step ensures a secure transaction. Thank you for your cooperation.</p>
        </>
      ),
      footer: (
        <Space size={12} reversed>
          <PrimaryButton 
            size="extra-small" 
            onclick={authenticate}
          >
            <span>Authenticate</span>
          </PrimaryButton>
          <CommonButton
            size="extra-small"
            onclick={done}
          >
            <span>Done and refresh</span>
          </CommonButton>
        </Space>
      ),
    });
  },
  singleConfirm(this: Modal, props: BoolvTemplateProps.SingleConfirm) {    
    const {
      zIndex,
      loading = false,
      showCancel = true,
      confirmText = 'OK',
      modalClass,
      title,
      content,
      icon = null,
      closeAfterConfirm = true,
    } = props;
    let confirming = false;
    const confirm = () => {
      if (confirming) return;
      confirming = true;
      props.onConfirm?.();
      closeAfterConfirm && (this.open = false);
      setTimeout(() => confirming = false, 500);
    }
    this.$resetAndPatch({
      open: true,
      key: props.key || '',
      modalClass,
      title,
      content,
      icon,
      zIndex,
      footer: (
        <Space size={12} reversed>
          <PrimaryButton
            size="extra-small"
            onclick={confirm}
            loading={loading}
            fontColor="#FFF"
            style={{ background: '#875EFF' }}>
            {confirmText}
          </PrimaryButton>
        </Space>
      ),
    });
  }
};

export default templates;
