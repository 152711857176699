<script setup>
import { clamp } from "../../utils";
import { useCreatorStore } from "../../stores";

const {
  timeline,
  seekTo,
  currentFrame,
  totalFrame,
  widthToFrame,
  frameToWidth,
} = useCreatorStore();
const scrollRef = inject("scrollRef");
const left = ref(0);
const dragStart = ref(false);
const dragging = ref(false);
const beforePosition = reactive({ pageX: 0 });
const style = computed(() => ({
  left: `${left.value - 5}px`,
}));

watch(
  () => timeline.frameWidth,
  (newStep, oldStep) => {
    if (!oldStep || !newStep) return;
    const newCursorX = left.value;
    const oldCursorX = left.value * (oldStep / newStep);
    scrollRef.value.scrollLeft += newCursorX - oldCursorX;
  },
  { flush: "post" }
);
watch([currentFrame, () => timeline.frameWidth], ([newCurrentFrame]) => {
  if (dragging.value) {
    return;
  }
  left.value = frameToWidth(newCurrentFrame);
});
onMounted(() => {
  document.addEventListener("mousemove", handleMouseMove);
  window.addEventListener("mouseup", handleMouseUp);
});
onUnmounted(() => {
  document.removeEventListener("mousemove", handleMouseMove);
  window.removeEventListener("mouseup", handleMouseUp);
});

function handleMouseMove(e) {
  if (dragStart.value) {
    drag(e);
  }
}

function handleMouseUp() {
  beforePosition.pageX = 0;

  dragStart.value = false;

  if (dragging.value) {
    dragging.value = false;
  }
}

function cursorDown(e) {
  dragStart.value = true;

  beforePosition.pageX = e.pageX;
  beforePosition.left = left.value;
}

function drag(e) {
  dragging.value = true;

  const deltaX = snapToGrid(beforePosition.pageX - e.pageX);
  const maxLeft = frameToWidth(totalFrame.value);
  const newLeft = clamp(beforePosition.left - deltaX, 0, maxLeft);

  left.value = newLeft;
  seekTo(widthToFrame(newLeft));
}

function snapToGrid(delta) {
  return Math.round(delta / timeline.frameWidth) * timeline.frameWidth;
}
</script>

<template>
  <div class="timeline-cursor" :style="style" @mousedown.stop="cursorDown">
    <div class="timeline-cursor-hd"></div>
    <div class="timeline-cursor-bd"></div>
  </div>
</template>
<style scoped>
.timeline-cursor {
  bottom: 0;
  position: absolute;
  top: 0;
  width: 11px;
  cursor: col-resize;
}
.timeline-cursor-hd {
  width: 11px;
  height: 16.5px;
  background-color: #646a73;
  border: 2px solid #646a73;
  border-bottom: none;
  left: 0;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 6;
}
.timeline-cursor-hd:after {
  background-color: #646a73;
  border-bottom: 2px solid #646a73;
  border-right: 2px solid #646a73;
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  transform: rotate(45deg) translate(2.8px, 2px);
  width: 7.78px;
  height: 7.79px;
}
.timeline-cursor-bd {
  background-color: #646a73;
  bottom: 0;
  left: 5px;
  position: absolute;
  top: 0px;
  width: 1px;
  z-index: 6;
}
</style>
