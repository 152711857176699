<script setup>
import { render } from "vue";
import { useCreatorStore, useCopyStore, useDragStore } from "../../stores";
import TimelineAxis from "./timeline-axis.vue";
import TimelineCursor from "./timeline-cursor.vue";
import Selection from "./selection.vue";
import SceneSegment from "../segments/scenesegment.vue";
import TextSegment from "../segments/textsegment.vue";
import SubtitleSegment from "../segments/subtitlesegment.vue";
import GraphicSegment from "../segments/graphicsegment.vue";
import ImageSegment from "../segments/imagesegment.vue";
import VideoSegment from "../segments/videosegment.vue";
import EffectSegment from "../segments/effectsegment.vue";
import StickerSegment from "../segments/stickersegment.vue";
import FilterSegment from "../segments/filtersegment.vue";
import AudioSegment from "../segments/audiosegment.vue";
import SpeechSegment from "../segments/speechsegment.vue";
import TransitionSegment from "../segments/transitionsegment.vue";
import { clamp } from "../../utils";

const segmentMap = {
  image: ImageSegment,
  video: VideoSegment,
  audio: AudioSegment,
  speech: SpeechSegment,
  text: TextSegment,
  sticker: StickerSegment,
  filter: FilterSegment,
  graphic: GraphicSegment,
  effect: EffectSegment,
  transition: TransitionSegment,
};
const {
  tracks,
  empty,
  timeline,
  totalFrame,
  activeNodeMap,
  seekTo,
  frameToWidth,
  widthToFrame,
} = useCreatorStore();
const { canPaste, paste } = useCopyStore();
const dragData = useDragStore();
const instance = getCurrentInstance();

const timelineRef = inject("timeline");

const scrollRef = ref(null);
const list = ref(null);
const ro = ref(null);
const width = ref(0);

const selectStart = ref(false);
const selectEnd = ref(false);
const event = ref();

const refLineLeft = ref(0);
const refLineRight = ref(0);
const refLineY = ref(0);
const showRefLineLeft = ref(false);
const showRefLineRight = ref(false);
const showRefLineY = ref(false);

const refLineLeftStyle = computed(() => ({
  left: `${refLineLeft.value}px`,
}));
const refLineRightStyle = computed(() => ({
  left: `${refLineRight.value}px`,
}));
const refLineYStyle = computed(() => ({
  top: `${refLineY.value}px`,
}));

const reversedTracks = computed(() => {
  const reversed = [];
  for (let i = tracks.value.length; i--; i >= 0) {
    const track = tracks.value[i];
    reversed.push(track);
  }
  return reversed;
});
const style = computed(() => ({
  width: `${width.value}px`,
}));
const contextMenuOptions = computed(() => [
  {
    label: "Paste",
    prefix: {
      name: "icon_paste",
    },
    disabled: !canPaste.value,
    onClick: paste,
  },
]);

onMounted(() => {
  ro.value = new ResizeObserver(resize);
  ro.value.observe(timelineRef.value);
});
onBeforeUnmount(() => {
  ro.value.disconnect();
  ro.value = null;
});

watch(
  tracks,
  (newTracks, oldTrakcs) => {
    if (newTracks.length > 0 && oldTrakcs.length === 0) {
      const bounding = scrollRef.value.getBoundingClientRect();
      const newTop = scrollRef.value.scrollHeight - bounding.height;
      scrollRef.value.scrollTop = newTop;
    }
  },
  { flush: "post" }
);
watch(totalFrame, (newTotalFrame) => {
  if (timeline.autoFit && newTotalFrame > 0) {
    const minWidth = timelineRef.value.clientWidth - 87;
    const newScale = Math.floor(
      minWidth / (newTotalFrame * 1.2) / timeline.baseline
    );
    timeline.scale = clamp(newScale, timeline.minScale, 60);
  }
  resize();
});
watch(() => timeline.frameWidth, resize);

function resize() {
  const minWidth = timelineRef.value.clientWidth - 87;
  const newWidth = frameToWidth(totalFrame.value * 1.2);
  width.value = Math.max(newWidth, minWidth);
}

function handleMouseDown(e) {
  activeNodeMap.clear();
  const bounding = list.value.getBoundingClientRect();

  selectStart.value = true;
  selectEnd.value = false;
  event.value = {
    pageX: e.pageX,
    pageY: e.pageY,
    x: e.pageX - bounding.x,
    y: e.pageY - bounding.y,
  };
}

function handleSelectEnd() {
  selectEnd.value = true;
}

function handleMouseEenter(e) {
  if (dragData.timelineEnterd) {
    return;
  }
  dragData.timelineEnterd = true;

  if (dragData.segment) {
    dragData.target.style.opacity = 0;
    dragData.segment.style.opacity = 1;
  } else if (dragData.target) {
    activeNodeMap.clear();

    const bounding = list.value.getBoundingClientRect();
    const x = e.pageX - bounding.x;
    const y = e.pageY - bounding.y;
    const { type, duration, ...restConfig } = dragData.data;
    const segmentComponent = segmentMap[type];
    const start = widthToFrame(x);
    const end = start + duration;
    const node = reactive({
      type,
      id: "dragTarget",
      isPrepared: true,
      conf: { ...dragData.data, start, end, active: true },
      getZIndex: () => 0,
      getTrack: () => null,
    });
    const newProps = { node, start, end, y, class: "active" };

    if (type === "image") newProps.src = restConfig.src;
    if (type === "video") newProps.coverPic = restConfig.coverPic;
    const vnode = h(segmentComponent, newProps);

    vnode.appContext = {
      ...instance.appContext,
      provides: { ...instance.appContext.provides, ...instance.provides },
    };
    render(vnode, list.value);
    const element = vnode.el;
    element.style.height = `${
      ["image", "video", "transition"].includes(type) ? 56 : 26
    }px`;
    dragData.target.style.opacity = 0;
    dragData.segment = element;
    element.dispatchEvent(new MouseEvent("mousedown", e));
  }
}

function handleLeaveContainer() {
  if (!dragData.timelineEnterd) {
    return;
  }
  dragData.timelineEnterd = false;

  if (dragData.segment) {
    dragData.segment.style.opacity = 0;
  }
  if (dragData.target) {
    dragData.target.style.opacity = 1;
  }
  showRefLineY.value = false;
  showRefLineLeft.value = false;
  showRefLineRight.value = false;
}

function handleClick(e) {
  if (selectEnd.value) return;
  const bounding = list.value.getBoundingClientRect();
  const frame = widthToFrame(e.pageX - bounding.x);
  seekTo(frame);
}

provide("timeline", timelineRef);
provide("scrollRef", scrollRef);
provide("trackList", list);
provide("refLineLeft", refLineLeft);
provide("refLineRight", refLineRight);
provide("refLineY", refLineY);
provide("showRefLineLeft", showRefLineLeft);
provide("showRefLineRight", showRefLineRight);
provide("showRefLineY", showRefLineY);
</script>
<template>
  <div
    class="timeline-scrollbar-wrapper"
    @mousedown="handleMouseDown"
    @mouseenter="handleMouseEenter"
    @mouseleave="handleLeaveContainer"
    @click="handleClick"
  >
    <div ref="scrollRef" class="timeline-scrollbar">
      <div class="track-list-wrapper">
        <bv-contextmenu :options="contextMenuOptions" :width="187">
          <div ref="list" class="track-list" :style="style">
            <div v-if="empty" class="empty-container">
              <div class="empty">
                <svg-icon name="editor_media" color="#8F959E" :size="18" />
                <span>Drop media here to get started</span>
              </div>
            </div>
            <div
              v-else
              v-for="track in reversedTracks"
              class="track"
              tabindex="-1"
              :key="track.id"
              :class="track.kind"
            >
              <template v-for="node in track.children" :key="node.id">
                <scene-segment
                  v-if="node.type === 'scene'"
                  :node="node.children[0]"
                />
                <transition-segment
                  v-if="node.type === 'transition'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  :node="node"
                />
                <text-segment
                  v-else-if="node.type === 'text'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:keyframes="node.conf.keyframes"
                  :node="node"
                />
                <subtitle-segment
                  v-else-if="node.type === 'subtitle'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:keyframes="node.conf.keyframes"
                  :node="node"
                />
                <graphic-segment
                  v-else-if="node.type === 'graphic'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:keyframes="node.conf.keyframes"
                  :node="node"
                />
                <image-segment
                  v-else-if="node.type === 'image'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:keyframes="node.conf.keyframes"
                  v-model:mask="node.conf.mask"
                  :node="node"
                  :src="node.conf.src"
                />
                <video-segment
                  v-else-if="node.type === 'video'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:ss="node.conf.ss"
                  v-model:keyframes="node.conf.keyframes"
                  v-model:mask="node.conf.mask"
                  :node="node"
                  :src="node.conf.src"
                />
                <sticker-segment
                  v-else-if="node.type === 'sticker'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:keyframes="node.conf.keyframes"
                  :node="node"
                />
                <audio-segment
                  v-else-if="node.type === 'audio'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:ss="node.conf.ss"
                  :node="node"
                />
                <speech-segment
                  v-else-if="node.type === 'speech'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:ss="node.conf.ss"
                  :node="node"
                />
                <effect-segment
                  v-else-if="node.type === 'effect'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  v-model:keyframes="node.conf.keyframes"
                  :node="node"
                />
                <filter-segment
                  v-else-if="node.type === 'filter'"
                  v-model:start="node.conf.start"
                  v-model:end="node.conf.end"
                  :node="node"
                />
              </template>
              <div v-show="!track.conf.visible" class="track-mask"></div>
            </div>
          </div>
        </bv-contextmenu>
        <timeline-cursor v-show="!empty" />
        <selection
          v-model="selectStart"
          :event="event"
          @selectend="handleSelectEnd"
        />
        <div
          v-show="showRefLineLeft"
          class="ref-line-x"
          :style="refLineLeftStyle"
        ></div>
        <div
          v-show="showRefLineRight"
          class="ref-line-x"
          :style="refLineRightStyle"
        ></div>
        <div
          v-show="showRefLineY"
          class="ref-line-y"
          :style="refLineYStyle"
        ></div>
      </div>
    </div>
    <timeline-axis />
  </div>
</template>
<style scoped>
.timeline-scrollbar-wrapper {
  position: absolute;
  left: 82px;
  right: 0;
  top: 0;
  bottom: 0;
}
.timeline-scrollbar {
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.25) transparent;
  position: relative;
}
.track-list-wrapper {
  position: absolute;
  top: 0;
  left: 5px;
  min-height: 100%;
  display: flex;
  align-items: stretch;
}
.track-list {
  padding: 54px 0 26px;
}
.track {
  background-color: #f3f5f7;
  color: #d4d4d4;
  position: relative;
  width: 100%;
  height: 26px;
}
.track + .track {
  margin-top: 10px;
}
.track:focus {
  background-color: #ebedef;
}
.track.primary,
.track.image {
  height: 56px;
}
.empty-container {
  width: 100%;
  height: 56px;
  padding-left: 16px;
  padding-right: 26px;
}
.empty svg {
  margin-right: 12px;
}
.empty {
  width: 100%;
  height: 100%;
  background: #f3f5f7;
  color: #8f959e;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.track-mask {
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  pointer-events: none;
}
.ref-line-x {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  background-color: #875eff;
  z-index: 9;
}
.ref-line-y {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #875eff;
  z-index: 5;
}
:deep(.el-scrollbar__bar) {
  z-index: 7;
}
:deep(.segment-shadow) {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #d5d6d7;
  pointer-events: none;
}
</style>
