<script setup>
import TimelineOperation from "./timeline-operation.vue";
import TrackList from "./track-list.vue";
import TimelineTools from "./timeline-tools.vue";
import { useCreatorStore, useDraftStore, useHistoryStore } from "../../stores";
import { clamp } from "../../utils";

const { empty, timeline, removeActiveNodes } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

const timelineRef = ref(null);

function remove() {
  removeActiveNodes();
  commit();
  updateDraft();
}

function handleWheel(e) {
  if (e.ctrlKey) {
    timeline.scale = clamp(
      timeline.scale - timeline.scale * e.deltaY * 0.01,
      timeline.minScale,
      timeline.maxScale
    );
    timeline.autoFit = false;
  }
}

provide("timeline", timelineRef);
</script>

<template>
  <div class="timeline-container" tabindex="-1" @keydown.delete.stop="remove">
    <timeline-tools />
    <div
      class="timeline"
      ref="timelineRef"
      @wheel="handleWheel"
    >
      <track-list />
      <timeline-operation v-show="!empty" />
    </div>
  </div>
</template>
<style scoped>
.timeline-container {
  width: 100%;
  height: 100%;
  outline: none;
}
.timeline {
  width: 100%;
  height: calc(100% - 48px);
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
</style>
